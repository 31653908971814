<template>
    <div class="modal-body">
        <div class="bordered-group">
            <div class="form-check">
                <label class="clickable">
                    <input type="checkbox" id="prijmyPodnikani" v-model="applicant.activeSections.incomes.incomeTS.incomeCheckboxes.prijmyPodnikani" @click="collapse" /> Příjmy z podnikání
                </label>
                <img class="group-reset"
                     src="@/assets/icons/update-auto.svg"
                     @click="resetPrijmyPodnikani"
                     v-bTooltip="$t('global.defaultValues')" />
            </div>
            <div class="collapse" id="prijmyPodnikaniContent">
                <div class="bordered-group-inner">
                    <div class="row">
                        <div class="col-md-12">
                            <v-labeled-button-select label="Vedení nákladové evidence"
                                                     :value="applicant.incomes.lastTaxSettlementCz.taxSettlementBusinessCz.outcomeTypeCz"
                                                     @update:value="val => applicant.incomes.lastTaxSettlementCz.taxSettlementBusinessCz.outcomeTypeCz = val"
                                                     :items="[{name: 'Paušál', value: 'FlatRate'}, {name: 'Daňová evidence', value: 'CostRecords'}, {name: 'Účetnictví', value: 'Bookkeeping'}]"
                                                     selectId="outcomeType"
                                                     @change="outcomeTypeChanged" />
                        </div>
                        <div class="col-md-12">
                            <v-labeled-button-select v-if="isFlatRate"
                                                     label="Paušál"
                                                     :value="applicant.incomes.lastTaxSettlementCz.taxSettlementBusinessCz.flatRateCz"
                                                     @update:value="val => applicant.incomes.lastTaxSettlementCz.taxSettlementBusinessCz.flatRateCz = val"
                                                     :items="flatRates" 
                                                     selectId="flatRatePercentage" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <v-labeled-select-list selectName="czNace" 
                                                   label="Druh činnosti (CZ-NACE)" 
                                                   v-model="applicant.incomes.lastTaxSettlementCz.taxSettlementBusinessCz.czNaceCz" 
                                                   :items="czNaces" />
                        </div>
                        <div class="col-md-6">
                            <v-labeled-select-list selectName="occupation"
                                                   label="Svobodné povolání"
                                                   v-model="applicant.incomes.lastTaxSettlementCz.taxSettlementBusinessCz.occupationCz"
                                                   :items="occupations" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="hypo-form-group inline-form-check">
                                <label></label>
                                <div class="hypo-input">
                                    <div class="form-check check-small">
                                        <label>
                                            <input type="checkbox" v-model="applicant.activeSections.incomes.incomeTS.incomeCheckboxes.prijmyObrat" id="cbTurnoverIncome" /> Příjem z obratu RB a alternativní doložení příjmu HB
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="applicant.activeSections.incomes.incomeTS.incomeCheckboxes.prijmyObrat">
                        <div class="col-md-6">
                            <v-labeled-input inputSize="49.3"
                                             label="Kreditní obrat za 1. měsíc"
                                             v-model="applicant.incomes.turnoverIncome.creditTurnover1Month"
                                             number="true"
                                             :addonString="$t('global.currencySymbol')"
                                             inputId="incomeCreditTurnover1Month">
                            </v-labeled-input>
                        </div>
                        <div class="col-md-6">
                            <v-labeled-input inputSize="49.3"
                                             label="Suma nezap. položek"
                                             v-model="applicant.incomes.turnoverIncome.uncreditedAmount1Month"
                                             number="true"
                                             :addonString="$t('global.currencySymbol')"
                                             inputId="incomeUncreditedAmount1Month"
                                             :inputTooltip="uncreditedAmountTooltip">
                            </v-labeled-input>
                        </div>
                        <div class="col-md-6">
                            <v-labeled-input inputSize="49.3"
                                             label="Kreditní obrat za 2. měsíc"
                                             v-model="applicant.incomes.turnoverIncome.creditTurnover2Month"
                                             number="true"
                                             :addonString="$t('global.currencySymbol')"
                                             inputId="incomeCreditTurnover2Month">
                            </v-labeled-input>
                        </div>
                        <div class="col-md-6">
                            <v-labeled-input inputSize="49.3"
                                             label="Suma nezap. položek"
                                             v-model="applicant.incomes.turnoverIncome.uncreditedAmount2Month"
                                             number="true"
                                             :addonString="$t('global.currencySymbol')"
                                             inputId="incomeUncreditedAmount2Month"
                                             :inputTooltip="uncreditedAmountTooltip">
                            </v-labeled-input>
                        </div>
                        <div class="col-md-6">
                            <v-labeled-input inputSize="49.3"
                                             label="Kreditní obrat za 3. měsíc"
                                             v-model="applicant.incomes.turnoverIncome.creditTurnover3Month"
                                             number="true"
                                             :addonString="$t('global.currencySymbol')"
                                             inputId="incomeCreditTurnover3Month">
                            </v-labeled-input>
                        </div>
                        <div class="col-md-6">
                            <v-labeled-input inputSize="49.3"
                                             label="Suma nezap. položek"
                                             v-model="applicant.incomes.turnoverIncome.uncreditedAmount3Month"
                                             number="true"
                                             :addonString="$t('global.currencySymbol')"
                                             inputId="incomeUncreditedAmount3Month"
                                             :inputTooltip="uncreditedAmountTooltip">
                            </v-labeled-input>
                        </div>

                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="hypo-form-group">
                                <h3>Minulé období</h3>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <v-labeled-input label="ř. 101 - Příjmy z podnikání"
                                             v-model="applicant.incomes.lastTaxSettlementCz.taxSettlementBusinessCz.businessIncome"
                                             number="true"
                                             :addonString="$t('global.currencySymbol')"
                                             inputId="incomesFromBusiness"
                                             @blured="lastBusinessBlured">
                            </v-labeled-input>
                        </div>
                        <div class="col-md-4">
                            <v-labeled-input label="ř. 37 - Základ daně z podnikání"
                                             v-model="applicant.incomes.lastTaxSettlementCz.taxSettlementBusinessCz.businessTaxBase"
                                             :addonString="$t('global.currencySymbol')"
                                             :iconDef="inputIcon(isFlatRate, applicant.activeSections.incomes.incomeTS.disabledInputs.businessTaxBaseInputs.lastBusiness)"
                                             :overrideValue="autoloadMinuleZaklad"
                                             :disabledInput="applicant.activeSections.incomes.incomeTS.disabledInputs.businessTaxBaseInputs.lastBusiness && isFlatRate"
                                             :iconTooltip="applicant.activeSections.incomes.incomeTS.disabledInputs.businessTaxBaseInputs.lastBusiness ? 'Upravit' : 'Vypočítat'"
                                             number="true" minus="true"
                                             inputId="incomesFromBusinessTaxBase"
                                             @overrideClicked="applicant.activeSections.incomes.incomeTS.disabledInputs.businessTaxBaseInputs.lastBusiness = false"
                                             @overrideHandler="setLastBusinessHandler" />
                        </div>
                        <div class="col-md-4">
                            <v-labeled-input v-if="!isFlatRate"
                                             label="Odpisy"
                                             v-model="applicant.incomes.lastTaxSettlementCz.taxSettlementBusinessCz.depreciation"
                                             :addonString="$t('global.currencySymbol')"
                                             number="true" />
                        </div>

                        <div class="col-md-12">
                            <div class="hypo-form-group inline-form-check">
                                <label></label>
                                <div class="hypo-input">
                                    <div class="form-check check-small">
                                        <label>
                                            <input type="checkbox" v-model="applicant.incomes.lastTaxSettlementCz.taxSettlementBusinessCz.useDeclaredExpenses" id="cbIncomesBussinessUseDeclaredExpenses" /> Uplatnit výdaje dle prohlášení klienta
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4" v-if="applicant.incomes.lastTaxSettlementCz.taxSettlementBusinessCz.useDeclaredExpenses">
                            <v-labeled-input label="Výdaje dle prohlášení o výši skutečných výdajů (RB)"
                                             v-model="applicant.incomes.lastTaxSettlementCz.taxSettlementBusinessCz.declaredExpenses"
                                             :addonString="$t('global.currencySymbol')"
                                             number="true" />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="hypo-form-group" style="padding: 0">
                                <h3>Předminulé období</h3>
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="hypo-input">
                                            <div class="form-check check-small">
                                                <label>
                                                    <input type="checkbox" v-model="applicant.activeSections.incomes.incomeTS.activeTSs.business.previousTaxSettlementHidden" id="cbIncomesFromBusinessSecondPeriod" /> DP nebylo doloženo
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6" style="padding-top: 6px;" v-if="!applicant.activeSections.incomes.incomeTS.activeTSs.business.previousTaxSettlementHidden">
                                        <a class="clickable" @click="copyTSs">Zkopírovat údaje z minulého období</a>
                                    </div>
                                </div>
                            </div>

                            <div class="row" v-if="!applicant.activeSections.incomes.incomeTS.activeTSs.business.previousTaxSettlementHidden">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <div class="form-check check-small">
                                                <label>
                                                    <input type="checkbox" v-model="applicant.activeSections.incomes.incomeTS.usingFlatRate.business.previousTaxSettlementFlatRate" @change="flatRateCbChanged" id="businessPreviousTaxSettlementFlatRateCb" /> Uplatňuji paušál
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4" style="padding-top: 4px;">
                                            <span class="btn-smallPadding">
                                                <v-button-select v-if="applicant.activeSections.incomes.incomeTS.usingFlatRate.business.previousTaxSettlementFlatRate"
                                                                 selectId="flatRatePercentagePreviousTS"
                                                                 :items="flatRates"
                                                                 :selectedValue="applicant.incomes.previousTaxSettlementCz.taxSettlementBusinessCz.flatRateCz"
                                                                 @update:selectedValue="val => applicant.incomes.previousTaxSettlementCz.taxSettlementBusinessCz.flatRateCz = val">
                                                </v-button-select>
                                            </span>
                                        </div>
                                    </div>                                    
                                </div>

                                <div class="col-md-4">
                                    <v-labeled-input inputSize="49.3"
                                                     label="ř. 101 - Příjmy z podnikání"
                                                     v-model="applicant.incomes.previousTaxSettlementCz.taxSettlementBusinessCz.businessIncome"
                                                     number="true"
                                                     :addonString="$t('global.currencySymbol')"
                                                     inputId="incomesFromBusinessSecondPeriod"
                                                     @blured="previousBusinessBlured">
                                    </v-labeled-input>
                                </div>
                                <div class="col-md-4">
                                    <v-labeled-input inputSize="49.3"
                                                     label="ř. 37 - Základ daně z podnikání"
                                                     v-model="applicant.incomes.previousTaxSettlementCz.taxSettlementBusinessCz.businessTaxBase"
                                                     number="true" minus="true"
                                                     :addonString="$t('global.currencySymbol')"
                                                     :iconDef="inputIcon(applicant.activeSections.incomes.incomeTS.usingFlatRate.business.previousTaxSettlementFlatRate, applicant.activeSections.incomes.incomeTS.disabledInputs.businessTaxBaseInputs.previousBusiness)"
                                                     :overrideValue="autoloadPredminuleZaklad"
                                                     :disabledInput="applicant.activeSections.incomes.incomeTS.disabledInputs.businessTaxBaseInputs.previousBusiness && applicant.activeSections.incomes.incomeTS.usingFlatRate.business.previousTaxSettlementFlatRate"
                                                     :iconTooltip="applicant.activeSections.incomes.incomeTS.disabledInputs.businessTaxBaseInputs.previousBusiness ? 'Upravit' : 'Vypočítat'"
                                                     inputId="incomesFromBusinessSecondPeriodTaxBase"
                                                     @overrideClicked="applicant.activeSections.incomes.incomeTS.disabledInputs.businessTaxBaseInputs.previousBusiness = false"
                                                     @overrideHandler="setPreviousBusinessHandler">
                                    </v-labeled-input>
                                </div>
                                <div class="col-md-4">
                                    <v-labeled-input v-if="!applicant.activeSections.incomes.incomeTS.usingFlatRate.business.previousTaxSettlementFlatRate"
                                                     inputSize="49.3"
                                                     label="Odpisy"
                                                     v-model="applicant.incomes.previousTaxSettlementCz.taxSettlementBusinessCz.depreciation"
                                                     number="true"
                                                     :addonString="$t('global.currencySymbol')">
                                    </v-labeled-input>
                                </div>
                            </div>
                        </div>
                        

                        <div class="col-md-12">
                            <div class="hypo-form-group" style="padding: 0">
                                <h3>Třetí daňové přiznání <i class="fas fa-info-circle" v-bPopover="'Relevantní pouze pro Raiffeisen stavební spořitelna'"></i></h3>
                                
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="hypo-input">
                                            <div class="form-check check-small">
                                                <label>
                                                    <input type="checkbox" v-model="applicant.activeSections.incomes.incomeTS.activeTSs.business.prePreviousTaxSettlementHidden" id="cbIncomesFromBusinessThirdPeriod" /> DP nebylo doloženo
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6" style="padding-top: 6px;" v-if="!applicant.activeSections.incomes.incomeTS.activeTSs.business.prePreviousTaxSettlementHidden">
                                        <a class="clickable" @click="copyToThirdTS">Zkopírovat údaje z minulého období</a>
                                    </div>
                                </div>
                            </div>

                            <div class="row" v-if="!applicant.activeSections.incomes.incomeTS.activeTSs.business.prePreviousTaxSettlementHidden">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <div class="form-check check-small">
                                                <label>
                                                    <input type="checkbox" v-model="applicant.activeSections.incomes.incomeTS.usingFlatRate.business.prePreviousTaxSettlementFlatRate" @change="flatRateCbChanged" id="businessPreviousTaxSettlementFlatRateCb" /> Uplatňuji paušál
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-4" style="padding-top: 4px;">
                                            <span class="btn-smallPadding">
                                                <v-button-select v-if="applicant.activeSections.incomes.incomeTS.usingFlatRate.business.prePreviousTaxSettlementFlatRate"
                                                                 selectId="flatRatePercentagePrePreviousTS"
                                                                 :items="flatRates"
                                                                 :selectedValue="applicant.incomes.prePreviousTaxSettlementCz.taxSettlementBusinessCz.flatRateCz"
                                                                 @update:selectedValue="val => applicant.incomes.prePreviousTaxSettlementCz.taxSettlementBusinessCz.flatRateCz = val">
                                                </v-button-select>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <v-labeled-input inputSize="49.3"
                                                     label="ř. 101 - Příjmy z podnikání"
                                                     v-model="applicant.incomes.prePreviousTaxSettlementCz.taxSettlementBusinessCz.businessIncome"
                                                     number="true"
                                                     :addonString="$t('global.currencySymbol')"
                                                     inputId="incomesFromBusinessThirdPeriod"
                                                     @blured="prePreviousBusinessBlured">
                                    </v-labeled-input>
                                </div>
                                <div class="col-md-4">
                                    <v-labeled-input inputSize="49.3"
                                                     label="ř. 37 - Základ daně z podnikání"
                                                     v-model="applicant.incomes.prePreviousTaxSettlementCz.taxSettlementBusinessCz.businessTaxBase"
                                                     number="true" minus="true"
                                                     :addonString="$t('global.currencySymbol')"
                                                     :iconDef="inputIcon(applicant.activeSections.incomes.incomeTS.usingFlatRate.business.prePreviousTaxSettlementFlatRate, applicant.activeSections.incomes.incomeTS.disabledInputs.businessTaxBaseInputs.prePreviousBusiness)"
                                                     :overrideValue="autoloadThirdZaklad"
                                                     :disabledInput="applicant.activeSections.incomes.incomeTS.disabledInputs.businessTaxBaseInputs.prePreviousBusiness && applicant.activeSections.incomes.incomeTS.usingFlatRate.business.prePreviousTaxSettlementFlatRate"
                                                     :iconTooltip="applicant.activeSections.incomes.incomeTS.disabledInputs.businessTaxBaseInputs.prePreviousBusiness ? 'Upravit' : 'Vypočítat'"
                                                     inputId="incomesFromBusinessThirdPeriodTaxBase"
                                                     @overrideClicked="applicant.activeSections.incomes.incomeTS.disabledInputs.businessTaxBaseInputs.prePreviousBusiness = false"
                                                     @overrideHandler="setPrePreviousBusinessHandler">
                                    </v-labeled-input>
                                </div>
                                <div class="col-md-4">
                                    <v-labeled-input v-if="!applicant.activeSections.incomes.incomeTS.usingFlatRate.business.prePreviousTaxSettlementFlatRate"
                                                     inputSize="49.3"
                                                     label="Odpisy"
                                                     v-model="applicant.incomes.prePreviousTaxSettlementCz.taxSettlementBusinessCz.depreciation"
                                                     number="true"
                                                     :addonString="$t('global.currencySymbol')">
                                    </v-labeled-input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="bordered-group">
            <div class="form-check">
                <label class="clickable">
                    <input type="checkbox" id="prijmyPronajem" v-model="applicant.activeSections.incomes.incomeTS.incomeCheckboxes.prijmyPronajem" @click="collapse" /> Příjmy z pronájmu
                </label>
                <img class="group-reset"
                     src="@/assets/icons/update-auto.svg"
                     @click="resetPrijmyPronajem"
                     v-bTooltip="$t('global.defaultValues')" />
            </div>
            <div class="collapse" id="prijmyPronajemContent">

                <div class="bordered-group-inner">
                    <div class="row">
                        <div class="col-md-4">
                            <v-labeled-button-select label="Paušál" 
                                                     :items="[{name: 'ANO', value: true}, {name: 'NE', value: false}]" 
                                                     :value="applicant.incomes.lastTaxSettlementCz.taxSettlementLeaseCz.leaseFlatRate"
                                                     @update:value="val => applicant.incomes.lastTaxSettlementCz.taxSettlementLeaseCz.leaseFlatRate = val"
                                                     selectId="leaseFlatRate" 
                                                     @change="leaseFlatRateChanged">
                            </v-labeled-button-select>
                        </div>
                        <div class="col-md-12">
                            <div class="hypo-form-group">
                                <h3>Minulé období</h3>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <v-labeled-input inputSize="49.3"
                                             label="ř. 201 - Příjmy z pronájmu"
                                             v-model="applicant.incomes.lastTaxSettlementCz.taxSettlementLeaseCz.leaseIncome"
                                             number="true"
                                             :addonString="$t('global.currencySymbol')"
                                             inputId="incomesFromRent"
                                             @blured="lastLeaseBlured">

                            </v-labeled-input>
                        </div>
                        <div class="col-md-4">
                            <v-labeled-input inputSize="49.3"
                                             label="ř. 39 - Základ daně z pronájmu"
                                             v-model="applicant.incomes.lastTaxSettlementCz.taxSettlementLeaseCz.leaseTaxBase"
                                             number="true" minus="true"
                                             :addonString="$t('global.currencySymbol')"
                                             :iconDef="inputIcon(applicant.incomes.lastTaxSettlementCz.taxSettlementLeaseCz.leaseFlatRate, applicant.activeSections.incomes.incomeTS.disabledInputs.leaseTaxBaseInputs.lastLease)"
                                             :overrideValue="autoloadPronajemZaklad"
                                             :disabledInput="applicant.activeSections.incomes.incomeTS.disabledInputs.leaseTaxBaseInputs.lastLease && applicant.incomes.lastTaxSettlementCz.taxSettlementLeaseCz.leaseFlatRate"
                                             :iconTooltip="applicant.activeSections.incomes.incomeTS.disabledInputs.leaseTaxBaseInputs.lastLease ? 'Upravit' : 'Vypočítat'"
                                             inputId="incomesFromRentTaxBase"
                                             @overrideClicked="applicant.activeSections.incomes.incomeTS.disabledInputs.leaseTaxBaseInputs.lastLease = false"
                                             @overrideHandler="setLastLeaseHandler">
                            </v-labeled-input>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="hypo-form-group" style="padding: 0">
                                <h3>Předminulé období <i class="fas fa-info-circle" v-bPopover="'Relevantní pouze pro Raiffeisen stavební spořitelna'"></i></h3>
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="hypo-input">
                                            <div class="form-check check-small">
                                                <label>
                                                    <input type="checkbox" v-model="applicant.activeSections.incomes.incomeTS.activeTSs.lease.previousTaxSettlementHidden" id="cbIncomesFromRentSecondPeriod" /> DP nebylo doloženo
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6" style="padding-top: 6px;" v-if="!applicant.activeSections.incomes.incomeTS.activeTSs.lease.previousTaxSettlementHidden">
                                        <a class="clickable" @click="copyLeaseTS">Zkopírovat údaje z minulého období</a>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="!applicant.activeSections.incomes.incomeTS.activeTSs.lease.previousTaxSettlementHidden">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <div class="form-check check-small">
                                                <label>
                                                    <input type="checkbox" v-model="applicant.incomes.previousTaxSettlementCz.taxSettlementLeaseCz.leaseFlatRate" @change="flatRateCbChanged" id="previousLeaseFlatRateCb" /> Uplatňuji paušál
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <v-labeled-input label="ř. 201 - Příjmy z pronájmu"
                                                     v-model="applicant.incomes.previousTaxSettlementCz.taxSettlementLeaseCz.leaseIncome"
                                                     number="true" :addonString="$t('global.currencySymbol')"
                                                     inputId="incomesFromRentSecondPeriod"
                                                     @blured="previousLeaseBlured">
                                    </v-labeled-input>
                                </div>
                                <div class="col-md-4">
                                    <v-labeled-input label="ř. 39 - Základ daně z pronájmu"
                                                     v-model="applicant.incomes.previousTaxSettlementCz.taxSettlementLeaseCz.leaseTaxBase"
                                                     number="true" minus="true"
                                                     :addonString="$t('global.currencySymbol')"
                                                     :iconDef="inputIcon(applicant.incomes.previousTaxSettlementCz.taxSettlementLeaseCz.leaseFlatRate, applicant.activeSections.incomes.incomeTS.disabledInputs.leaseTaxBaseInputs.previousLease)"
                                                     :overrideValue="autoloadPronajemMinuleZaklad"
                                                     :disabledInput="applicant.activeSections.incomes.incomeTS.disabledInputs.leaseTaxBaseInputs.previousLease && applicant.incomes.previousTaxSettlementCz.taxSettlementLeaseCz.leaseFlatRate"
                                                     :iconTooltip="applicant.activeSections.incomes.incomeTS.disabledInputs.leaseTaxBaseInputs.previousLease ? 'Upravit' : 'Vypočítat'"
                                                     inputId="incomesFromRentSecondPeriodTaxBase"
                                                     @overrideClicked="applicant.activeSections.incomes.incomeTS.disabledInputs.leaseTaxBaseInputs.previousLease = false"
                                                     @overrideHandler="setPreviousLeaseHandler">
                                    </v-labeled-input>
                                </div>
                            </div>

                        </div>
                        
                        <div class="col-md-12">
                            <div class="hypo-form-group" style="padding: 0">
                                <h3>Třetí daňové přiznání <i class="fas fa-info-circle" v-bPopover="'Relevantní pouze pro Raiffeisen stavební spořitelna'"></i></h3>
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="hypo-input">
                                            <div class="form-check check-small">
                                                <label>
                                                    <input type="checkbox" v-model="applicant.activeSections.incomes.incomeTS.activeTSs.lease.prePreviousTaxSettlementHidden" id="cbIncomesFromRentThirdPeriod" /> DP nebylo doloženo
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6" style="padding-top: 6px;" v-if="!applicant.activeSections.incomes.incomeTS.activeTSs.lease.previousTaxSettlementHidden">
                                        <a class="clickable" @click="copyPreviousLeaseTS">Zkopírovat z předminulého období</a>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="!applicant.activeSections.incomes.incomeTS.activeTSs.lease.prePreviousTaxSettlementHidden">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <div class="form-check check-small">
                                                <label>
                                                    <input type="checkbox" v-model="applicant.incomes.prePreviousTaxSettlementCz.taxSettlementLeaseCz.leaseFlatRate" @change="flatRateCbChanged" id="prePreviousLeaseFlatRateCb" /> Uplatňuji paušál
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <v-labeled-input label="ř. 201 - Příjmy z pronájmu"
                                                     v-model="applicant.incomes.prePreviousTaxSettlementCz.taxSettlementLeaseCz.leaseIncome"
                                                     number="true" :addonString="$t('global.currencySymbol')"
                                                     inputId="incomesFromRentThirdPeriod"
                                                     @blured="prePreviousLeaseBlured">
                                    </v-labeled-input>
                                </div>
                                <div class="col-md-4">
                                    <v-labeled-input label="ř. 39 - Základ daně z pronájmu"
                                                     v-model="applicant.incomes.prePreviousTaxSettlementCz.taxSettlementLeaseCz.leaseTaxBase"
                                                     number="true" minus="true"
                                                     :addonString="$t('global.currencySymbol')"
                                                     :iconDef="inputIcon(applicant.incomes.prePreviousTaxSettlementCz.taxSettlementLeaseCz.leaseFlatRate, applicant.activeSections.incomes.incomeTS.disabledInputs.leaseTaxBaseInputs.prePreviousLease)"
                                                     :overrideValue="autoloadPronajemPredMinuleZaklad"
                                                     :disabledInput="applicant.activeSections.incomes.incomeTS.disabledInputs.leaseTaxBaseInputs.prePreviousLease && applicant.incomes.prePreviousTaxSettlementCz.taxSettlementLeaseCz.leaseFlatRate"
                                                     :iconTooltip="applicant.activeSections.incomes.incomeTS.disabledInputs.leaseTaxBaseInputs.prePreviousLease ? 'Upravit' : 'Vypočítat'"
                                                     inputId="incomesFromRentThirdPeriodTaxBase"
                                                     @overrideClicked="applicant.activeSections.incomes.incomeTS.disabledInputs.leaseTaxBaseInputs.prePreviousLease = false"
                                                     @overrideHandler="setPrePreviousLeaseHandler">
                                    </v-labeled-input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row taxInputs">
            <div class="col-md-6">
                <v-labeled-input label="ř. 54 - Odčitatelné položky" 
                                 v-model="applicant.incomes.lastTaxSettlementCz.nonTaxableIncomeBase" 
                                 number="true" 
                                 :addonString="$t('global.currencySymbol')" 
                                 inputId="nonTaxableIncomeBase"></v-labeled-input>
                <v-labeled-input label="ř. 70 - Sleva na dani" 
                                 v-model="applicant.incomes.lastTaxSettlementCz.taxCredit" 
                                 number="true" 
                                 :addonString="$t('global.currencySymbol')" 
                                 inputId="taxDiscount"></v-labeled-input>
            </div>
            <div class="col-md-6">
                <v-labeled-combobox label="ř. 72 - Daňové zvýhodnění na děti" 
                                    v-model="applicant.incomes.lastTaxSettlementCz.childTaxReturn" 
                                    number="true" 
                                    :addonString="$t('global.currencySymbol')" 
                                    inputId="childTaxReturn" 
                                    :items="childTaxReturns"></v-labeled-combobox>
                <v-labeled-input label="ř. 74 - Daň po uplatnění slevy" 
                                 v-model="applicant.incomes.lastTaxSettlementCz.tax" 
                                 number="true" 
                                 :addonString="$t('global.currencySymbol')" 
                                 iconDef="fas fa-calculator" 
                                 :overrideValue="autoloadDan" 
                                 iconTooltip="Vypočítat" 
                                 labelTooltip="Ujistěte se, že je toto pole správně vyplněné." 
                                 labelClass="label-red" 
                                 inputId="tax"></v-labeled-input>
            </div>
        </div>

        <div class="modal fade" id="confirmModal" tabindex="-1" role="dialog" aria-labelledby="confirmModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title" id="confirmModalLabel">{{ $t('credit.applicant.incomes.taxConfirmation.title') }}</h4>
                        <button type="button" class="close" @click="hideConfirmModal" aria-label="$t('credit.incomesTSModal.closeWindow')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        {{ $t('credit.applicant.incomes.taxConfirmation.insertedTax') }} {{ numberWithCommas(this.applicant.incomes.lastTaxSettlementCz.tax) }} {{$t('global.currencySymbol')}} {{ $t('credit.applicant.incomes.taxConfirmation.notComplyWithTax') }} {{ numberWithCommas(this.autoloadDan) }} {{$t('global.currencySymbol')}}.
                        <br />
                        {{ $t('credit.applicant.incomes.taxConfirmation.taxQuestion') }}
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-success" @click="overrideTax">{{ $t('credit.applicant.incomes.taxConfirmation.change') }}</button>
                        <button type="button" class="btn btn-primary" data-dismiss="modal" @click="saveChanges" id="btnIncomesSaveChangesTaxConfirmation">{{ $t('credit.applicant.incomes.taxConfirmation.keep') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import LabeledInput from '../LabeledInput.vue'
    import LabeledSelectList from '../LabeledSelectList.vue'
    import LabeledCombobox from '../LabeledCombobox.vue'
    import LabeledButtonSelect from '../LabeledButtonSelect.vue'
    import ButtonSelect from '../ButtonSelect.vue'

    import { ResetTSIncomes, ResetTSIncomesBusiness, ResetTSIncomesLease } from '../../js/HouseholdHelpers.mjs'

    export default {
        name: 'v-incomes-ts-modal-body-cz',
        components: {
            'v-labeled-input': LabeledInput,
            'v-labeled-select-list': LabeledSelectList,
            'v-labeled-combobox': LabeledCombobox,
            'v-labeled-button-select': LabeledButtonSelect,
            'v-button-select': ButtonSelect
        },
        data() {
            return {
                taxBaseDisabledInputs: {
                    lastBusiness: true,
                    previousBusiness: true,
                    prePreviousBusiness: true,
                    lastLease: true,
                    previousLease: true,
                    prePreviousLease: true
                },
                overrideSetterFunctions: {
                    lastBusiness: null,
                    previousBusiness: null,
                    prePreviousBusiness: null,
                    lastLease: null,
                    previousLease: null,
                    prePreviousLease: null
                },
                onlyForRB: 'Relevantní pouze pro Raiffeisen stavební spořitelna'
            }
        },
        watch: {
            'applicant.incomes.lastTaxSettlementCz': {
                handler() {
                    this.$emit('update:applicant', this.applicant);
                },
                deep: true
            },
            'applicant.incomes.previousTaxSettlementCz': {
                handler() {
                    this.$emit('update:applicant', this.applicant);
                },
                deep: true
            },
            'applicant.incomes.lastTaxSettlementCz.taxSettlementBusinessCz.flatRateCz'() {
                this.applicant.incomes.lastTaxSettlementCz.taxSettlementBusinessCz.businessTaxBase = this.autoloadMinuleZaklad;
            },
            'applicant.incomes.previousTaxSettlementCz.taxSettlementBusinessCz.flatRateCz'() {
                this.applicant.incomes.previousTaxSettlementCz.taxSettlementBusinessCz.businessTaxBase = this.autoloadPredminuleZaklad;
            },
            'applicant.incomes.prePreviousTaxSettlementCz.taxSettlementBusinessCz.flatRateCz'() {
                this.applicant.incomes.prePreviousTaxSettlementCz.taxSettlementBusinessCz.businessTaxBase = this.autoloadThirdZaklad;
            },
        },
        computed: {
            isFlatRate: function () {
                return this.applicant.incomes.lastTaxSettlementCz.taxSettlementBusinessCz.outcomeTypeCz === 'FlatRate';
            },
            autoloadMinuleZaklad: function () {
                if (this.isFlatRate) {
                    return this.countBussinessIncomeBase(this.applicant.incomes.lastTaxSettlementCz.taxSettlementBusinessCz.businessIncome, 2000000, this.applicant.incomes.lastTaxSettlementCz.taxSettlementBusinessCz.flatRateCz);
                }
                return this.applicant.incomes.lastTaxSettlementCz.taxSettlementBusinessCz.businessTaxBase;
            },
            autoloadPredminuleZaklad: function () {
                if (this.applicant.activeSections.incomes.incomeTS.usingFlatRate.business.previousTaxSettlementFlatRate) {
                    return this.countBussinessIncomeBase(this.applicant.incomes.previousTaxSettlementCz.taxSettlementBusinessCz.businessIncome, 2000000, this.applicant.incomes.previousTaxSettlementCz.taxSettlementBusinessCz.flatRateCz);
                }
                return this.applicant.incomes.previousTaxSettlementCz.taxSettlementBusinessCz.businessTaxBase;
            },
            autoloadThirdZaklad: function () {
                if (this.applicant.activeSections.incomes.incomeTS.usingFlatRate.business.prePreviousTaxSettlementFlatRate) {
                    return this.countBussinessIncomeBase(this.applicant.incomes.prePreviousTaxSettlementCz.taxSettlementBusinessCz.businessIncome, 2000000, this.applicant.incomes.prePreviousTaxSettlementCz.taxSettlementBusinessCz.flatRateCz);
                }
                return this.applicant.incomes.prePreviousTaxSettlementCz.taxSettlementBusinessCz.businessTaxBase;
            },
            leaseIsFlatRate: function () {
                return this.applicant.incomes.lastTaxSettlementCz.taxSettlementLeaseCz.leaseFlatRate;
            },
            autoloadPronajemZaklad: function () {
                if (this.leaseIsFlatRate) {
                    return this.countLeaseIncomeBase(this.applicant.incomes.lastTaxSettlementCz.taxSettlementLeaseCz.leaseIncome);
                }
                return null;
            },
            autoloadPronajemMinuleZaklad: function () {
                if (this.applicant.incomes.previousTaxSettlementCz.taxSettlementLeaseCz.leaseFlatRate) {
                    return this.countLeaseIncomeBase(this.applicant.incomes.previousTaxSettlementCz.taxSettlementLeaseCz.leaseIncome);
                }
                return null;
            },
            autoloadPronajemPredMinuleZaklad: function () {
                if (this.applicant.incomes.prePreviousTaxSettlementCz.taxSettlementLeaseCz.leaseFlatRate) {
                    return this.countLeaseIncomeBase(this.applicant.incomes.prePreviousTaxSettlementCz.taxSettlementLeaseCz.leaseIncome);
                }
                return null;
            },
            autoloadDan: function () {
                var incomeCheckoxes = this.applicant.activeSections.incomes.incomeTS.incomeCheckboxes;
                var taxSettlement = this.applicant.incomes.lastTaxSettlementCz;
                var r37 = incomeCheckoxes.prijmyPodnikani ? taxSettlement.taxSettlementBusinessCz.businessTaxBase : 0;
                var r39 = incomeCheckoxes.prijmyPronajem ? taxSettlement.taxSettlementLeaseCz.leaseTaxBase : 0;
                var lastTaxSettlement = this.applicant.incomes.lastTaxSettlementCz;
                var r54 = lastTaxSettlement.nonTaxableIncomeBase;
                var r70 = lastTaxSettlement.taxCredit;
                var r72 = lastTaxSettlement.childTaxReturn;

                let taxBase = r37 + r39 - r54;
                const lowerPartMax = 1867728;
                let tax = 0;
                if (taxBase < lowerPartMax) {
                    tax = taxBase * 0.15 - r70;
                }
                else {
                    tax = lowerPartMax * 0.15 + (taxBase - lowerPartMax) * 0.23 - r70;
                }
                if (tax < 0) tax = 0;
                return Math.max(0, Math.round(tax - r72));
            }
        },
        methods: {
            leaseFlatRateChanged(value) {
                if (value) {
                    this.$nextTick(() => {
                        this.overrideSetterFunctions.lastLease();
                        this.$nextTick(() => {
                            this.overrideSetterFunctions.previousLease();
                            this.$nextTick(() => {
                                this.overrideSetterFunctions.prePreviousLease();
                            })
                        })
                    })
                }
            },
            outcomeTypeChanged(value) {
                this.$nextTick(() => {
                    if (value === "FlatRate") {
                        this.overrideSetterFunctions.lastBusiness();

                        this.$nextTick(() => {
                            //Preprevious tax settlement
                            this.applicant.activeSections.incomes.incomeTS.usingFlatRate.business.prePreviousTaxSettlementFlatRate = true;
                            this.$nextTick(() => {
                                this.overrideSetterFunctions.prePreviousBusiness();

                                //Previous tax settlement
                                this.$nextTick(() => {
                                    this.applicant.activeSections.incomes.incomeTS.usingFlatRate.business.previousTaxSettlementFlatRate = true;
                                    this.$nextTick(() => {
                                        this.overrideSetterFunctions.previousBusiness();
                                    })
                                })
                            })
                        })
                    }
                    else {
                        this.applicant.activeSections.incomes.incomeTS.usingFlatRate.business.prePreviousTaxSettlementFlatRate = false;
                        this.applicant.activeSections.incomes.incomeTS.usingFlatRate.business.previousTaxSettlementFlatRate = false;
                    }
                })
            },
            flatRateCbChanged(e) {
                const id = e.target.id;
                const checked = e.target.checked;
                if (checked) {
                    this.$nextTick(() => {
                        switch (id) {
                            case 'businessPreviousTaxSettlementFlatRateCb':
                                this.overrideSetterFunctions.previousBusiness();
                                break;
                            case 'businessPrePreviousTaxSettlementFlatRateCb':
                                this.overrideSetterFunctions.prePreviousBusiness();
                                break;
                            case 'previousLeaseFlatRateCb':
                                this.overrideSetterFunctions.previousLease();
                                break;
                            case 'prePreviousLeaseFlatRateCb':
                                this.overrideSetterFunctions.prePreviousLease();
                                break;
                            default:
                                throw 'Unknown element id';
                        }
                    })
                }
            },
            overrideTax() {
                this.applicant.incomes.lastTaxSettlementCz.tax = this.autoloadDan;
                this.hideConfirmModal();
            },
            hideConfirmModal() {
                // eslint-disable-next-line
                $('#confirmModal').modal('hide');
            },
            setPrePreviousLeaseHandler(fn) {
                this.overrideSetterFunctions.prePreviousLease = fn;
            },
            prePreviousLeaseBlured() {
                if (this.applicant.incomes.prePreviousTaxSettlementCz.taxSettlementLeaseCz.leaseFlatRate)
                    this.overrideSetterFunctions.prePreviousLease();
            },
            setPreviousLeaseHandler(fn) {
                this.overrideSetterFunctions.previousLease = fn;
            },
            previousLeaseBlured() {
                if (this.applicant.incomes.previousTaxSettlementCz.taxSettlementLeaseCz.leaseFlatRate)
                    this.overrideSetterFunctions.previousLease();
            },
            setLastLeaseHandler(fn) {
                this.overrideSetterFunctions.lastLease = fn;
            },
            lastLeaseBlured() {
                if (this.applicant.incomes.lastTaxSettlementCz.taxSettlementLeaseCz.leaseFlatRate)
                    this.overrideSetterFunctions.lastLease();
            },

            setPrePreviousBusinessHandler(fn) {
                this.overrideSetterFunctions.prePreviousBusiness = fn;
            },
            prePreviousBusinessBlured() {
                if (this.applicant.activeSections.incomes.incomeTS.usingFlatRate.business.prePreviousTaxSettlementFlatRate)
                    this.overrideSetterFunctions.prePreviousBusiness();
            },
            setPreviousBusinessHandler(fn) {
                this.overrideSetterFunctions.previousBusiness = fn;
            },
            previousBusinessBlured() {
                if (this.applicant.activeSections.incomes.incomeTS.usingFlatRate.business.previousTaxSettlementFlatRate)
                    this.overrideSetterFunctions.previousBusiness();
            },
            setLastBusinessHandler(fn) {
                this.overrideSetterFunctions.lastBusiness = fn;
            },
            lastBusinessBlured() {
                if (this.isFlatRate)
                    this.overrideSetterFunctions.lastBusiness();
            },

            inputIcon(isFlatRate, isDisabled) {
                if (isFlatRate) {
                    if (isDisabled) return "fas fa-edit";
                    return null
                }
                return null;
            },
            flatRateMultiplier(enumValue) {
                if (enumValue === 'FlatRate40') return 0.4;
                if (enumValue === 'FlatRate60') return 0.6;
                if (enumValue === 'FlatRate80') return 0.8;
                return 1;
            },
            countLeaseIncomeBase(income) {
                var vydaj = income * 0.3;
                if (vydaj > 300000) vydaj = 300000;
                return Math.round(income - vydaj);
            },
            countBussinessIncomeBase(income, countedIncomeLimit, flatRateEnum) {
                var countedIncome = income > countedIncomeLimit ? countedIncomeLimit : income;
                var vydaj = countedIncome * this.flatRateMultiplier(flatRateEnum);
                return Math.round(income - vydaj);
            },
            collapse: function (event) {
                /* eslint-disable */
                var id = event.currentTarget.id;
                var checkInput = document.getElementById(id);
                if (checkInput.checked) $('#' + id + 'Content').collapse('show');
                else $('#' + id + 'Content').collapse('hide');
            },
            copyTSs: function () {
                this.applicant.incomes.previousTaxSettlementCz.taxSettlementBusinessCz.businessIncome = this.applicant.incomes.lastTaxSettlementCz.taxSettlementBusinessCz.businessIncome;
                this.applicant.incomes.previousTaxSettlementCz.taxSettlementBusinessCz.businessTaxBase = this.applicant.incomes.lastTaxSettlementCz.taxSettlementBusinessCz.businessTaxBase;
                this.applicant.incomes.previousTaxSettlementCz.taxSettlementBusinessCz.businessTaxBase = this.autoloadPredminuleZaklad;
                this.applicant.incomes.previousTaxSettlementCz.taxSettlementBusinessCz.depreciation = this.applicant.incomes.lastTaxSettlementCz.taxSettlementBusinessCz.depreciation;
            },
            copyToThirdTS: function () {
                this.applicant.incomes.prePreviousTaxSettlementCz.taxSettlementBusinessCz.businessIncome = this.applicant.incomes.previousTaxSettlementCz.taxSettlementBusinessCz.businessIncome;
                this.applicant.incomes.prePreviousTaxSettlementCz.taxSettlementBusinessCz.businessTaxBase = this.applicant.incomes.previousTaxSettlementCz.taxSettlementBusinessCz.businessTaxBase;
                this.applicant.incomes.prePreviousTaxSettlementCz.taxSettlementBusinessCz.businessTaxBase = this.autoloadThirdZaklad;
                this.applicant.incomes.prePreviousTaxSettlementCz.taxSettlementBusinessCz.depreciation = this.applicant.incomes.previousTaxSettlementCz.taxSettlementBusinessCz.depreciation;
            },
            copyLeaseTS: function () {
                this.applicant.incomes.previousTaxSettlementCz.taxSettlementLeaseCz.leaseIncome = this.applicant.incomes.lastTaxSettlementCz.taxSettlementLeaseCz.leaseIncome;
                this.applicant.incomes.previousTaxSettlementCz.taxSettlementLeaseCz.leaseTaxBase = this.applicant.incomes.lastTaxSettlementCz.taxSettlementLeaseCz.leaseTaxBase;
            },
            copyPreviousLeaseTS: function () {
                this.applicant.incomes.prePreviousTaxSettlementCz.taxSettlementLeaseCz.leaseIncome = this.applicant.incomes.previousTaxSettlementCz.taxSettlementLeaseCz.leaseIncome;
                this.applicant.incomes.prePreviousTaxSettlementCz.taxSettlementLeaseCz.leaseTaxBase = this.applicant.incomes.previousTaxSettlementCz.taxSettlementLeaseCz.leaseTaxBase;
            },
            manageCollapses: function () {
                /* eslint-disable */
                if (this.applicant.activeSections.incomes.incomeTS.incomeCheckboxes.prijmyPodnikani) $('#prijmyPodnikaniContent').collapse('show');
                else $('#prijmyPodnikaniContent').collapse('hide');
                if (this.applicant.activeSections.incomes.incomeTS.incomeCheckboxes.prijmyPronajem) $('#prijmyPronajemContent').collapse('show');
                else $('#prijmyPronajemContent').collapse('hide');
            },
            resetModal: function () {
                ResetTSIncomes(this);
                this.disableBusinessTaxBaseInputs();
                this.disableLeaseTaxBaseInputs();
                this.manageCollapses();
            },
            resetPrijmyPodnikani: function () {
                ResetTSIncomesBusiness(this);
                this.disableBusinessTaxBaseInputs();
            },
            resetPrijmyPronajem: function () {
                ResetTSIncomesLease(this);
                this.disableLeaseTaxBaseInputs();
            },
            saveChanges() {
                this.$emit('saveChanges');
            },
            checkTax() {
                if (this.applicant.incomes.lastTaxSettlementCz.tax === this.autoloadDan) {
                    this.saveChanges();
                    // eslint-disable-next-line
                    $('#prijmyDPModal').modal('hide');
                }
                else {
                    // eslint-disable-next-line
                    $('#confirmModal').modal('show');
                }
            },
            numberWithCommas(x) {
                if (x) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                return x;
            },
            disableBusinessTaxBaseInputs() {
                this.applicant.activeSections.incomes.incomeTS.disabledInputs.businessTaxBaseInputs.lastBusiness = true;
                this.applicant.activeSections.incomes.incomeTS.disabledInputs.businessTaxBaseInputs.previousBusiness = true;
                this.applicant.activeSections.incomes.incomeTS.disabledInputs.businessTaxBaseInputs.prePreviousBusiness = true;
            },
            disableLeaseTaxBaseInputs() {
                this.applicant.activeSections.incomes.incomeTS.disabledInputs.leaseTaxBaseInputs.lastLease = true;
                this.applicant.activeSections.incomes.incomeTS.disabledInputs.leaseTaxBaseInputs.previousLease = true;
                this.applicant.activeSections.incomes.incomeTS.disabledInputs.leaseTaxBaseInputs.prePreviousLease = true;
            }
        },
        props: {
            tsProvider: String,
            applicant: Object,
            occupations: Array,
            czNaces: Array,
            flatRates: Array,
            childTaxReturns: Array
        },
        mounted() {
            this.observer = new MutationObserver(mutations => {
                for (const m of mutations) {
                    const newValue = m.target.className;
                    if (newValue === 'modal fade in') {
                        this.$nextTick(() => {
                            this.manageCollapses();
                        });
                    }
                }
            });
            // eslint-disable-next-line
            $(document).on('hidden.bs.modal', '.modal', function () {
                // eslint-disable-next-line
                $('.modal:visible').length && $(document.body).addClass('modal-open');
            });

            this.observer.observe(this.$parent.$refs.incomesTSModal, {
                attributes: true,
                attributeFilter: ['class'],
            });
            this.manageCollapses();
        },
        beforeDestroy() {
            this.observer.disconnect();
        }
    }
</script>

<style scoped src="./IncomesBodyStyle.css"></style>

<style>
    .btn-smallPadding .btn {
        padding: 4px 8px;
    }

    .flatRate-checkbox-outer {
        padding-left: 0;
        float: right;
        margin-bottom: 10px;
    }
</style>

