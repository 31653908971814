

const localeMessages = {
    cs: {
        global: {
            currencySymbol: 'Kč',
            years: 'let',
            defaultValues: 'Výchozí hodnoty',
            delete: 'Smazat',
            setDefault: 'Nastavit výchozí',
            setDefaultTitle: 'Přepíše aktuální hodnoty v okně na hodnoty výchozí.',
            cancel: 'Zavřít',
            save: 'Uložit',
            saveAndCount: 'Uložit a spočítat',
            months: 'měsíců',
            month: 'měsíc',
            months2: 'měsíce'
        },
        credit: {
            household: {
                zadatel: 'Žadatel',
                domacnost: 'Domácnost',
                addNewApplicant: 'Přidat dalšího žadatele',
                familyStatus: 'Rodinný stav',
                county: 'Kraj',
                numberOfPersons: 'Počet osob',
                child0_6: 'Děti do 6 let',
                child6_10: 'Děti 6 - 10 let',
                child10_15: 'Děti 10 - 15 let',
                child15_26: 'Děti 15 - 26 let',
                buttons: {
                    remove: 'Odebrat'
                }
            },
            applicant: {
                name: 'Jméno',
                lastname: 'Příjmení',
                incomes: {
                    monthlyNetIncome: 'Příjem ze závislé činnosti',
                    taxIncomes: 'Příjmy z DP',
                    otherIncomes: 'Ostatní příjmy',
                    taxConfirmation: {
                        title: 'Potvrzení výše daně',
                        insertedTax: 'Zadaná daň ve výši',
                        notComplyWithTax: 'neodpovídá očekávané výši',
                        taxQuestion: 'Chcete daň změnit nebo zachovat?',
                        keep: 'Zachovat',
                        change: 'Změnit'
                    },
                    otherConfirmation: {
                        title: 'Potvrzení počtu dětí',
                        text: 'Pokud není zadáno aspoň 1 dítě, nebude započítán příjem z:'
                    }
                },
                expenses: {
                    loans: 'Splátky úvěrů',
                    otherExpenses: 'Další výdaje',
                },
            },
            app: {
                rate: 'Sazba',
                maturity: 'Splatnost',
                loanPurpose: 'Účel úvěru',
                ltv: 'LTV',
                fixation: 'Fixace',
                propertyType: 'Typ nemovitosti',
                household: 'domácnost',
                loanAmount: 'Výše úvěru',
                reinsurance: 'Zajištění',
                age: 'Věk',
                ratesIncomes: 'Čisté příjmy žadatelů',
                addHousehold: 'Přidat domácnost',
                bonitaUnfilled: 'Nevyplněna',
                forAllVariants: 'Společné pro všechny varianty',
                validationYes: 'Vyhovující modelace',
                validationNo: 'Nevyhovující modelace',
                validationIndividial: 'Individuální posouzení',
                inProgress: ' Probíhá výpočet',
                buttons: {
                    setDefault: 'Nastavit výchozí',
                    countCredit: 'Spočítat Bonitu',
                    cancelProcessing: 'Přerušit výpočet',
                    goToRates: 'Přejít na výpočet sazeb',
                    setDefaultTitle: 'Přepíše aktuální hodnoty v okně na hodnoty výchozí',
                    countCreditTitle: 'Přepočítat spodní tabulku se souhrnem bonity dle zadaných údajů žadatelů',
                    cancelProcessingTitle: 'Přeruší probíhající výpočet',
                    goToRatesTitle: 'Výpočet aktuální sazby úvěru se zahrnutými údaji bonity klienta z horního formuláře',
                    householdTitle: '',
                    countRates: 'Spočítat sazby',
                    bonita: 'Bonita',
                    rates: 'Sazby',
                    count: 'Výpočet',
                    addVarinat: 'Přidat variantu',
                    removeVariant: 'Odebrat variantu',
                },
                userLogoutError: 'Došlo k odhlášení uživatele. Prosím obnovte stránku a znovu se přihlašte.',
                pdfGenerateError: 'Chyba při vytváření PDF, prosím zkuste znovu.',
                pdfNameWithBanks: 'kalkulace_bonity_banky.pdf',
                pdfNameWithoutBanks: 'kalkulace_bonity.pdf',
                appliacantCount: 'Počet žadatelů',
                incomesTotal: 'Celkové příjmy',
                oldestApplicant: 'Věk nejstaršího žadatele',
                mortgageApplication: 'Hypoteční úvěry',
                noResults: 'Nevyskytuje se shoda',
                skInfoText: '',
                banksList: 'Přehled bank',
                chart: 'Splátkový kalendář',
                modelations: 'Kalkulace',
            },
            resultTable: {
                dataLoading: 'Načítám data ...',
                bank: 'Banka',
                maximumAmount: 'Maximální výše úvěru',
                maturity: 'Splatnost',
                payment: 'Splátka',
                totalIncome: 'Výsledný příjem',
                dti: 'DTI',
                dsti: 'DSTI',
                dtiTitle: 'Představuje podíl celkové výše všech závazků na čistém ročním příjmu žadatele o úvěr.  Dle nařízení ČNB je horní hranice 8,5 násobek čistého ročního příjmu, výjimku mají žadatelé mladší 36 let věku na 9,5 násobek čistého ročního příjmu.',
                dstiTitle: 'Procentní podíl celkové výše všech měsíčních splátek na čistém měsíčním příjmu žadatele o úvěr. Výši limitu si upravují banky samostatně, bez regulace ČNB.',
                countedIncomed: 'Započítané příjmy',
                provider: 'Poskytovatel',
                rate: 'Úrok',
                fixation: 'Fixace',
                monthlyExpenses: 'Měsíční náklady',
                processingCosts: 'Náklady na zpracování',
                fixCostsPerYear: 'Fixační nákl. za rok',
                rpsn: 'RPSN',
                properties: 'Vlastnosti',
                maxAmountPerBonita: 'Max. úvěr dle bonity',
                legalNotice: 'Výpočet bonity je pouze orientační, pro přesný výpočet použijte oficiální kalkulátor banky.',
                reportCalculationError: 'Oznámit chybu',
                countedWithFixation: 'Bonita spočítána s fixací na',
                noResults: 'Nebyly nalezeny žádné nabídky',
                noBonita: 'Bonita nevyplněna',
                checkboxes: {
                    withoutDSTI: 'Bez omezení DTI/DSTI',
                    selectedOnly: 'Jen vybrané',
                    showDetail: 'Zobrazit detail'
                },
                buttons: {
                    methodologyFilter: 'Metodický filtr',
                    discountsAndFees: 'Slevy a poplatky',
                    saveCalculation: 'Uložit kalkulaci',
                    exportToPdf: 'Exportovat do PDF',
                    exportToPdfBankIncluding: 'Exportovat do PDF (včetně názvů bank)',
                    chooseAll: 'Vybrat vše',
                    printSelected: 'Tisk vybraných',
                    printCalculations: 'Tisk kalkulací',
                    select: 'Vybrat',
                    chart: 'Spl. kalendář',
                    arrange: 'Sjednat'
                },
                explanatios: {
                    discount: 'Akční nabídky',
                    methodology: 'Metodické upřesnění',
                    condition: 'Podmínky pro uplatnění slevy'
                }
            },
            resultTablePopUp: {
                saveTitle: 'Uložení kalkukace',
                saveSubtitle: 'Kam si přejete uložit stávající kalkulaci?',
                saveInfo: 'Kalkulace uloží všechna nastavení včetně všech použitých filtrů a slev. Uloží se zároveň i jednoduché zobrazení sazeb včetně Bonity domácností do PDF pro pozdější vygenerování',
                applicantSelectionTitle: 'Uložení nové kalkulace a přepsání již uložené kalkulace ke stávajícímu klientovi',
                applicantSelectionSubtitle: 'Pro uložení nové kalkulace klikněte na jméno klienta a označte jej, poté vyplňte název kalkulace a uložte ji.',
                applicantSelectionTitle2: 'Přepsání již uložené kalkulace u stávajícího klienta',
                applicantSelectionSubtitle2: 'Pro přepsání existující kalkulace klidněte na název kalkulace, případně upravte název kalkulace a změnu uložte ji.',
                applicantSelectionTableColumnName: 'Jméno',
                applicantSelectionTableColumnDate: 'Datum a čas výpočtu',
                applicantSelectionTableColumnTime: 'Čas výpočtu',
                applicantSelectionTableColumnNote: 'Poznámka',
                applicantSelectionDataLoading: 'Načítám uložené kalkulace.',
                applicantSelectionNoData: 'Nemáte žádné uložené kalkulace.',
                newApplicantTitle: 'Vytvoření nového klienta',
                newApplicantSubtitle: 'Kalkulace jsou identifikovány svým názvem a jsou zařazeny pod jména klienty.',
                modelationNameInputLabel: 'Název kalkulace',
                modelationNoteInputLabel: 'Poznámka',
                completedHeader1: 'kalkulace s názvem ',
                completedHeader2: 'byla úspěšně uložena ke klientovi ',
                moreInfo: 'Více o uložené kalkulace:',
                rates: 'Zadání sazeb',
                ratesPrint: 'PDF s výpočtem sazeb',
                credit: 'Zadání bonity',
                creditPrint: 'PDF s výpočtem bonity',
                confirmTitle: 'Potvrzení',
                confirmText: 'Opravdu si přejete uloženou kalkulaci přepsat?',
                calculationNameError: 'Zvolte prosím jiný název kalkulace. Nelze uložit dvě a více kalkulací se stejným názvem k jednomu klientovi.',
                useSearch: 'Využijte pole pro vyhledání klienta',
                applicantName: 'Jméno klienta',
                buttons: {
                    save: 'Uložit',
                    cancel: "Zavřít",
                    newClient: 'Nový klient',
                    existingClient: 'Stávající klient',
                    nameRequirements: 'Všechna pole musí být vyplněna, maximální počet znaků je 32'
                }
            },
            resultTableItem: {
                loanCant: 'Úvěr není možné poskytnout',
                onlineApproved: 'Ověřeno online',
                gotoPaymentChart: 'přejít na vývoj splátek',
                gotoBankOnlineContractSite: 'Přejít na online aplikaci',
                AppliedEmployeeIncome: 'ze závislé činnosti',
                AppliedBusinessIncome: 'z podnikání',
                AppliedBusinessIncomeDph: 'z obratu',
                AppliedLeaseIncome: 'z pronájmu',
                AppliedOtherIncome: 'ostatní příjmy',
                AppliedFlatRateIncome: 'z paušální daně',
                AppliedBusinessLegalEntityIncome: '',
            },
            resultTableItemError: {
                creditCantBeCounted: 'Bonitu nebylo možné vypočítat.'
            },
            resultTableItemLoading: {
                counting: 'Zjišťuji bonitu ...',
                canceled: 'Výpočet přerušen.'
            },
            expensesModal: {
                expenses: 'Výdaje',
                closeWindow: 'Zavřít okno',
                expensesLiving: 'Výdaje na domácnost',
                expensesInsurance: 'Pojištění',
                expensesLoans: 'Splátky úvěrů a HU, vyjma ref. úvěru',
                sumOfAllBalances: 'Objem nesplacených úvěrů a HU, vyjma ref. úvěru',
                expensesOverdraftLimits: 'Limit kontokorentu',
                expensesCreditCardLimits: 'Limit kreditní karty',
                expensesAlimony: 'Výživné',
                expensesLiability: 'Ručení',
                monthlyWageDeduction: 'Měsíční srážka ze mzdy',
                monthlyInsurance: 'Měsíční pojištění jakéhokoliv druhu',
                costsForOtherHouseholdPersons: 'Náklady na osoby žijící v jedné domácnosti',
                mortgageLoans: 'Splátky HÚ (vyjma ref. úvěru)',
                sumOfAllMortgagesLoanBalances: 'Objem nesplacených HÚ (vyjma ref. úvěru)'
            },
            incomesTSModal: {
                incomesDP: 'Příjem z daňového přiznání',
                closeWindow: "Zavřít okno",
            },
            incomesOtherModal: {
                otherIncomes: 'Ostatní příjmy',
                closeWindow: 'Zavřít okno',
                otherIncomesCz: {
                    starobniDuchod: 'Starobní důchod',
                    rodicovskyPrispevek: 'Rodičovský příspěvek',
                    invalidniDuchod12: 'Inv. důchod 1. a 2. stupně',
                    invalidniDuchod: 'Invalidní důchod 3. stupně',
                    dpc: 'DPČ',
                    najemniSmlouva: 'Příjem z pron. z náj. smluv',
                    materska: 'Peněžitý příspěvek v mateřštví',
                    odmenaPestouna: 'Odměna pěstouna',
                    vyzivne: 'Výživné',
                    cestovne: 'Cestovní náhrady (diety)',
                    budoucniPronajem: 'Budoucí příjem z pronájmu',
                    vysluhaPolicieVojaci: 'Renta výsluhová (policie a vojáci)',
                    nahradaZtratyVydelku: 'Trvalé náhrady za ztrátu výdělku',
                    vysluhaHornici: 'Renta výsluhová (horníci)',
                    vdovskyDuchod: 'Vdovský důchod',
                    prispevekNaBydleni: 'Příspěvek na bydlení nebo stabilizační příspěvek',
                    sirotciDuchod: 'Sirotčí důchod',
                    prijemZKapitalovehoMajetku: 'Čistý měsíční příjem z kapitálového majetku',
                    prispevekNaOsobniAsistenci: 'Příspěvek na osobní asistenci',
                    podporaVNezamestnanosti: 'Podpora v nezaměstnanosti'
                },
            },
            firstAccessModal: {
                waitAMoment: 'Ještě chviličku ...',
                waitAMomentDescription: 'Některé údaje pro výpočet bonity jsou jiné, než ve výpočtu sazeb. Vyberte prosím, jaké parametry chcete použít pro výpočet bonity.',
                ltvQuestion: 'Jaké chcete použít LTV pro výpočet bonity?',
                fixationQestion: 'Jakou chcete použít fixaci pro výpočet bonity?',
                continue: 'Pokračovat',
                ltvFromPrevCalc: 'LTV z předešlého výpočtu',
                maxLtv: 'Max LTV',
                ltvFromRates: 'LTV z kalkulace sazeb',
                fixationFromPrevCalc: 'Fixace z předešlého výpočtu',
                fixationFromRates: 'Fixace z kalkulace sazeb',
                years: {
                    variable: 'Variabilní',
                    oneYear: '1 rok',
                    twoYears: '2 roky',
                    threeYears: '3 roky',
                    fourYears: '4 roky',
                    years: 'let'
                }
            },
            discountsAndFeesModal: {
                selectAll: 'Vybrat vše',
                tradeDiscount: 'Obchodní sleva',
                processingCosts: 'Náklady na zpracování',
                monthlyCosts: 'Měsíční náklady',
                specificVariants: 'Jednotlivé varianty',
                allVariants: 'Všechny varianty',
                fillCosts: 'Zvolit poplatek',
                poplatekZaOdhad: 'Poplatek za odhad',
                poplatekZaZpracovani: 'Poplatek za zpracování',
                poplatekZaCerpani: 'Poplatek za čerpání',
                poplatekZaCerpaniZS: 'Poplatek za čerpání na návrh na vklad ZS',
                other: 'Jiné',
                poplatekZaSpravu: 'Poplatek za správu úvěru',
                poplatekZaVedeni: 'Poplatek za vedení účtu',
                uverovePojisteni: 'Úvěrové / Životní pojištění',
                pojisteniNemovitosti: 'Pojištění nemovitosti',
            },
            methodologyFilterModal: {
                title: 'Metodický filtr',
                banks: 'Výběr bank',
                parameters: 'Parametry výběru',
                buttons: {
                    showInMethodology: 'Zobrazit v metodice'
                }
            },
            menuModal: {
                home: 'Úvod',
                hypo: 'Hypoteční',
                personal: 'Spotřebitelské',
                unsecured: 'Nezajištěné',
                loans: 'úvěry',
                methodology: 'Metodika',
                newCalculation: 'Nová kalkulace',
                savedCalculations: 'Uložené kalkulace',
                hypoOnline: 'Online žádosti',
                manual: 'Návody',
                news: 'Novinky',
                bankList: 'Produktové karty bank',
                paymentCalendar: 'Splátkový kalendář'
            }
            
        },
        storedCalc: {
            title: 'Uložené kalkulace',
            table: {
                modelationTitle: 'Jméno klienta / název kalkulace',
                modelationDateTime: 'Datum zadání kalkulace',
                modelationDateTimeShort: 'Datum kalkulace',
                date: 'Datum',
                rates: 'Výpočet sazeb',
                credit: 'Výpočet bonity',
                loading: 'Načítám uložené kalkulace.',
                noCalculations: 'Nemáte žádné uložené kalkulace.',
                clientTitle: 'Jméno klienta',
                calculationTitle: 'Název kalkulace',
                action: 'Akce',
                delete: 'Smazat',
            }
        },
        tooltip: {
            credit: {
                app: {
                    loanAmount: 'Kolik peněz si klient potřebuje půjčit.',
                    reinsurance: 'Hodnota nemovitosti, kterou klient může dát bance do zástavy. Nejčastěji kupní cena anebo celková cena nemovitostí, které může klient poskytnout jako zástavu.',
                    rate: 'Úroková sazba, kterou žadatel dostane na hypotéku.',
                    maturity: 'Doba po kterou bude klient úvěr splácet. Maximální doba splatnosti zpravidla bývá do 70 let věku nejstaršího žadatele.',
                    loanPurpose: 'Za jakým účelem si klient bere úvěr.',
                    ltv: '"Loan to value" Úvěr k hodnotě. Poměr výše hypotečního úvěru k zástavní hodnotě nemovitosti.',
                    fixation: 'Doba platnosti úrokové sazby, tj. doba, po kterou je úrok neměnný. U variabilní sazby se úrok mění podle aktuální situace na trhu.',
                    propertyType: 'O jakou nemovitost se jedná.',
                    refinMortgageBalance: 'Celková aktuální výše úvěru, který chcete refinancovat.',
                    refinMortgageIncrease: 'Celková část o kterou chcete refinancovaný úvěr navyšovat.',
                    age: 'Věk nejstaršího ze žadatelů o úvěr.',
                    ratesIncomes: 'Celkové měsíční příjmy všech žadatelů. Hodnota slouží pro výpočet sazeb.'
                },
                resultTable: {
                    fixation: 'Doba platnosti úrokové sazby',
                    mothlyExpenses: 'Výše měsíčních poplatků nad rámec splátky',
                    processingCosts: 'Celkové poplatky na vyřízení úvěru',
                    financialCostPerYear: 'Průměrné roční náklady na úvěr během první fixace - zaplacená částka na úrocích a poplatcích (po odečtení výnosu) za každý rok první fixace',
                    rpsn: 'Roční procentní sazba nákladů je číslo, které má umožnit spotřebiteli lépe vyhodnotit výhodnost nebo nevýhodnost poskytovaného úvěru',
                    properties: 'Ostatní vlastnosti hypotéky'
                },
                household: {
                    familyStatus: 'Rodinný stav žadatele o úvěr.',
                    county: 'Oblast trvalého bydliště žadatelů.',
                },
                applicant: {
                    name: 'Jméno žadatele',
                    lastname: 'Příjmení žadatele',
                    vek: 'Věk žadatele.',
                    incomes: {
                        monthlyNetIncome: 'Čistý měsíční příjem ze zaměstnání žadatele.'
                    },
                    expenses: {
                        loans: 'Celkové měsíční splátky úvěrů.'
                    }
                },
                incomesOtherModal: {
                    otherIncomesCz: {
                        starobniDuchod: 'Měsíční výše starobního důchodu.',
                        rodicovskyPrispevek: 'Měsíční výše rodičovského příspěvku (dávka, která se vyplácí po mateřské). Do příjmu se uvádí v případě, kdy je již přiznaný. Do počtu osob musí být vždy zahrnuty děti, na které je pobírán rodičovský příspěvek.',
                        invalidniDuchod12: 'Měsíční výše invalidního důchodu 1. a 2. stupně.',
                        invalidniDuchod: 'Měsíční výše invalidního důchodu 3. stupně.',
                        dpc: 'Průměrná měsíční výše za poslední 3 měsíce z dohody o pracovní činnosti.',
                        najemniSmlouva: 'Měsíční výše příjmu z pronájmu z nájemních smluv',
                        materska: 'Měsíční výše peněžité pomoci v mateřství (vyplácí se v souvislosti s těhotenstvím, mateřstvím a péčí o novorozence). Rodiče nejprve čerpají mateřskou, poté rodičovský příspěvek. Do počtu osob musí být vždy zahrnuty děti, na které je pobírána mateřská.',
                        odmenaPestouna: 'Měsíční výše odměn pěstouna.',
                        vyzivne: 'Měsíční výše výživného (alimentů), které rodič dostává. Do počtu osob musí být vždy zahrnuty děti, na které je pobíráno výživné.',
                        cestovne: 'Měsíční výše diet (cestovních náhrad).',
                        budoucniPronajem: 'Měsíční příjem z budoucího pronájmu (bez služeb).',
                        vysluhaPolicieVojaci: 'Měsíční výše výsluhového příspěvku (renty) policistů a vojáků.',
                        nahradaZtratyVydelku: 'Měsíční výše trvalých náhrad za ztrátu výdělku úrazem nebo nemocí.',
                        vysluhaHornici: 'Měsíční výše výsluhového příspěvku (renty) horníků.',
                        vdovskyDuchod: 'Měsíční výše vdovského důchodu.',
                        prispevekNaBydleni: 'Měsíční výše příspěvku na bydlení a stabilizačního příspěvku pro vojáky.',
                        sirotciDuchod: 'Měsíční výše sirotčího důchodu.',
                        prijemZKapitalovehoMajetku: ' ',
                        prispevekNaOsobniAsistenci: ' ',
                        podporaVNezamestnanosti: ' '
                    },
                },
                expensesModal: {
                    expensesLiving: 'Měsíční výdaje na domácnost (spotřeba, jídlo, atd.).',
                    expensesInsurance: 'Celkové měsíční výdaje na pojištění (životní, majetek, auto,...).',
                    expensesLoans: 'Celkové měsíční splátky úvěrů a HU.',
                    sumOfAllBalances: 'Celkový objem všech nesplacených úvěrů a HU',
                    expensesOverdraftLimits: 'Aktuální limit kontokorentu na účtech žadatelů o úvěr.',
                    expensesCreditCardLimits: 'Aktuální limit kreditních karet na účtech žadatelů o úvěr.',
                    expensesAlimony: 'Celkové měsíční výdaje na vyživované děti.',
                    expensesLiability: 'Celkové měsíční výdaje na platby ručitelských závazků.',
                    monthlyWageDeduction: ' ',
                    monthlyInsurance: ' ',
                    costsForOtherHouseholdPersons: ' ',
                    mortgageLoans: 'Celkové měsíční splátky HÚ',
                    sumOfAllMortgagesLoanBalances: 'Celkový objem nesplacených HÚ'
                }
            },
            storedCalc: {
                table: {
                    deleteClient: 'Smazat klienta',
                    deleteModelation: 'Smazat kalkulaci',
                    ratesModelation: 'Přejít do výpočtu sazeb',
                    ratesPdf: 'Exportovat do PDF s výsledky v době uložení',
                    creditModelation: 'Přejít do výpočtu bonity',
                    creditPdf: 'Exportovat do PDF s výsledky v době uložení'
                }
            }
        },
        error: {
            couldNotConnectToBackend: 'Nepodařilo se připojit k backendu aplikace. Pokud bude problém přetrvávat, prosím kontaktujte podporu pomocí kontaktního formuláře.',
            couldNotFindMethodologyItem: 'Položku se nepodařilo najít v metodice. Pokud bude problém přetrvávat, prosím kontaktujte podporu pomocí kontaktního formuláře.',
            loanAmountTooHigh: 'Výše úvěru nesmí být vyšší než 90 % hodnoty zajištění'
        }
    },
    sk: {
        global: {
            currencySymbol: '€',
            years: 'rokov',
            defaultValues: 'Predvolené hodnoty',
            delete: 'Zmazať',
            setDefault: 'Zobraziť predvolené',
            setDefaultTitle: 'Prepíše aktuálnej hodnoty v okne na hodnoty predvolené.',
            cancel: 'Zavrieť',
            save: 'Uložiť',
            saveAndCount: 'Uložiť a spočítať',
            months: 'mesiacov',
            month: 'mesiac',
            months2: 'mesiace'
        },
        credit: {
            household: {
                zadatel: 'Žiadateľ',
                domacnost: 'Domácnosť',
                addNewApplicant: 'Pridať ďalšieho žiadateľa',
                familyStatus: 'Rodinný stav',
                county: 'Kraj',
                numberOfPersons: 'Počet osôb',
                child0_6: 'Vyživované dieťa',
                child6_10: 'Dieťa 6 - 10 rokov',
                child10_15: 'Dieťa 10 - 15 rokov',
                child15_26: 'Dieťa 15 - 26 rokov',
                buttons: {
                    remove: 'Odobrať'
                }
            },
            applicant: {
                name: 'Meno',
                lastname: 'Priezvisko',
                incomes: {
                    monthlyNetIncome: 'Príjem zo závislej činnosti',
                    taxIncomes: 'Príjmy z podnikania',
                    otherIncomes: 'Ostatné príjmy',
                    taxConfirmation: {
                        title: 'Potvrdenie výška dane',
                        insertedTax: 'Zadaná daň vo výške',
                        notComplyWithTax: 'nezodpovedá očakávanej výške',
                        taxQuestion: 'Chcete daň zmeniť alebo zachovať?',
                        keep: 'Zachovať',
                        change: 'Zmeniť'
                    }
                },
                expenses: {
                    loans: 'Splátky existujúcich úverov',
                    otherExpenses: 'Splátky SÚ, HÚ a ostatné záväzky',
                }
            },
            app: {
                rate: 'Sadzba',
                maturity: 'Splatnosť',
                loanPurpose: 'Účel úveru',
                ltv: 'LTV',
                fixation: 'Fixácia',
                propertyType: 'Typ nehnuteľnosti',
                household: 'domácnosť',
                addHousehold: 'Pridať domácnosť',
                loanAmount: 'Výška úveru',
                reinsurance: 'Hodnota nehnuteľnosti',
                age: 'Vek',
                ratesIncomes: 'Príjem hl. žiadateľa',
                bonitaUnfilled: 'Nevyplnená',
                forAllVariants: 'Spoločné pre všetky varianty',
                validationYes: 'Vyhovujúca modelácia',
                validationNo: 'Nevyhovujúce modulácie',
                validationIndividial: 'Individuálne posúdenie',
                inProgress: ' Prebieha výpočet',
                buttons: {
                    setDefault: 'Zobraziť predvolené',
                    countCredit: 'Spočítať bonitu',
                    cancelProcessing: 'Prerušiť výpočet',
                    goToRates: 'Prejsť na výpočet sadzieb',
                    setDefaultTitle: 'Prepíše súčasné hodnoty na hodnoty predvolené',
                    countCreditTitle: 'Prepočítať spodnú tabuľku s prehľadom bonity podľa zadaných údajov žiadateľov',
                    cancelProcessingTitle: 'Prerušiť prebiehajúci výpočet',
                    goToRatesTitle: 'Výpočet aktuálnej sadzby úveru so zahrnutými údajmi bonity klienta z horného formulára',
                    householdTitle: 'Vyplniť v prípade, že do úveru vstupujú ďalšie osoby (solidárny spoludlžník, ručiteľ), ktorých príjmy budú posudzované v bonite. Rovnako použiť v prípade vzťahu druh/družka z dôvodu správneho započítania životného minima.',
                    countRates: 'Spočítať sadzby',
                    bonita: 'Bonita',
                    rates: 'Sadzby',
                    count: 'Výpočet',
                    addVarinat: 'Pridať variantu',
                    removeVariant: 'Odobrať variantu',
                },
                userLogoutError: 'Došlo k odhláseniu užívateľa. Prosím obnovte stránku a znovu sa prihláste.',
                pdfGenerateError: 'Chyba pri vytváraní PDF, prosím skúste znova.',
                pdfNameWithBanks: 'bonita-vratane-bank.pdf',
                pdfNameWithoutBanks: 'bonita.pdf',
                appliacantCount: 'Počet žiadateľov',
                incomesTotal: 'Celkové príjmy',
                oldestApplicant: 'Vek najstaršieho žiadateľa',
                mortgageApplication: 'Hypotekárne úvery',
                noResults: 'Nevyskytuje sa zhoda',
                skInfoText: 'Pre 365 bank, Prima banku, Slovenskú sporiteľňu, Tatra banku a VÚB banku je použitý všeobecný prepočet bonity v súlade s nástrojmi finančnej stability Národnej banky Slovenka uvedenými na',
                banksList: 'Prehľad bánk',
                chart: 'Splátkový kalendár',
                modelations: 'Kalkulácie',
            },
            resultTable: {
                dataLoading: 'Načítavam data ...',
                bank: 'Banka',
                maximumAmount: 'Maximálna výška úveru',
                maturity: 'Splatnosť',
                payment: 'Splátka úveru',
                totalIncome: 'Akceptovateľný príjem',
                dti: 'DTI',
                dsti: 'DSTI',
                dtiTitle: '"Debt to income" Výška všetkých úverov nesmie presiahnuť 8 násobok ročného príjmu.',
                dstiTitle: '"Debt service to income" Výška splátok všetkých úverov nesmie prekročiť 60% všetkých mesačných príjmov.',
                countedIncomed: 'Započítané príjmy',
                provider: 'Poskytovateľ',
                rate: 'Úroková sadzba',
                fixation: 'Fixácia',
                monthlyExpenses: 'Mesačné náklady',
                processingCosts: 'Náklady za spracovanie',
                fixCostsPerYear: 'Fixačný nákl. za rok',
                rpsn: 'RPMN',
                properties: 'Vlastnosti',
                maxAmountPerBonita: 'Max. úver podľa bonity',
                legalNotice: 'Výpočet bonity je len orientačný, pre presný výpočet použite oficiálnu kalkulačku banky.',
                reportCalculationError: 'Oznámit chybu',
                countedWithFixation: 'Bonita spočítaná s fixáciou na',
                noResults: 'Neboli nájdené žiadne ponuky',
                noBonita: 'Bonita nevyplnená',
                checkboxes: {
                    withoutDSTI: 'Bez obmedzenia DTI/DSTI',
                    selectedOnly: 'Len vybrané',
                    showDetail: 'Zobraziť všetky ponuky'
                },
                buttons: {
                    methodologyFilter: 'Metodický filter',
                    discountsAndFees: 'Zľavy a poplatky',
                    saveCalculation: 'Uložiť modeláciu',
                    exportToPdf: 'Exportovať do PDF',
                    exportToPdfBankIncluding: 'Exportovať do PDF (vrátane názvov bánk)',
                    chooseAll: 'Vybrať všetko',
                    printSelected: 'Tlač vybraných',
                    printCalculations: 'Tlač kalkulácií',
                    select: 'Vybrať',
                    chart: 'Spl. kalendár',
                    arrange: 'Dojednať'
                },
                explanatios: {
                    discount: 'Aktuálne kampane bánk',
                    methodology: 'Metodické upresnenie',
                    condition: 'Podmienky ovplyvňujúce výšku úrokovej sadzby'
                }
            },
            resultTablePopUp: {
                saveTitle: 'Uloženie kalkulácie',
                saveSubtitle: 'Kam si prajete uložiť existujúcu kalkuláciu?',
                saveInfo: 'Kalkulácia uloží všetky nastavenia vrátane všetkých použitých filtrov a zliav. Uloží sa zároveň aj jednoduché zobrazenie taríf vrátane Bonita domácností do PDF pre neskoršie vygenerovanie',
                applicantSelectionTitle: 'Uloženie novej kalkulácie a prepísanie už uloženej kalkulácie k existujúcemu klientovi',
                applicantSelectionSubtitle: 'Kalkulácia bude priradená k vybranému klientovi. Klienta je možné vybrať kliknutím na jeho meno. Vyplňte názov kalkulácie a uložte ju.',
                applicantSelectionTitle2: 'Prepísanie už uloženej kalkulácie u existujúceho klienta',
                applicantSelectionSubtitle2: 'Pre prepísanie existujúcej kalkulácie upokojte na názov kalkulácie, prípadne upravte názov kalkulácie a zmenu uložte ju.',
                applicantSelectionTableColumnName: 'Meno',
                applicantSelectionTableColumnDate: 'Dátum a čas výpočtu',
                applicantSelectionTableColumnTime: 'Čas výpočtu',
                applicantSelectionTableColumnNote: 'Poznámka',
                applicantSelectionDataLoading: 'Načítavam uložené kalkulácie.',
                applicantSelectionNoData: 'Nemáte žiadne uložené kalkulácie.',
                newApplicantTitle: 'Vytvorenie nového klienta',
                newApplicantSubtitle: 'Kalkulácie sú identifikované svojim názvom a sú zaradené pod mená klientov.',
                modelationNameInputLabel: 'Názov kalkulácie',
                modelationNoteInputLabel: 'Poznámka',
                completedHeader1: 'Kalkulácia s názvom ',
                completedHeader2: 'bola úspešne uložená ku klientovi ',
                moreInfo: 'Viac o uloženej kalkulácii:',
                rates: 'Zadanie sadzieb',
                ratesPrint: 'PDF s výpočtom sadzieb',
                credit: 'Zadanie bonity',
                creditPrint: 'PDF s výpočtom bonity',
                confirmTitle: 'Potvrdenie',
                confirmText: 'Naozaj si prajete uloženú kalkuláciu prepísať?',
                calculationNameError: 'Zvoľte prosím iný názov kalkulácie. Nie je možné uložiť dve a viac kalkulácií s rovnakým názvom k jednému klientovi.',
                useSearch: 'Využite pole pre vyhľadanie klienta',
                applicantName: 'Meno klienta',
                buttons: {
                    save: 'Uložiť',
                    cancel: "Zavrieť",
                    newClient: 'Nový klient',
                    existingClient: 'Existujúci klient',
                    nameRequirements: 'Všetky polia musia byť vyplnené, maximálny počet znakov je 32'
                }
            },
            resultTableItem: {
                loanCant: 'Úver nie je možné poskytnúť',
                onlineApproved: 'Overené online',
                gotoPaymentChart: 'prejsť na vývoj splátok',
                gotoBankOnlineContractSite: 'Prejsť na online aplikáciu',
                AppliedEmployeeIncome: 'zo závislej činnosti',
                AppliedBusinessIncome: 'z podnikania FO',
                AppliedBusinessIncomeDph: 'z podnikania obrat',
                AppliedLeaseIncome: 'z prenájmu',
                AppliedOtherIncome: 'ostatné príjmy',
                AppliedFlatRateIncome: '',
                AppliedBusinessLegalEntityIncome: 'z podnikania PO',
            },
            resultTableItemError: {
                creditCantBeCounted: 'Bonitu nebolo možné vypočítať.'
            },
            resultTableItemLoading: {
                counting: 'Zisťujem bonitu ...',
                canceled: 'Výpočet prerušený.'
            },
            expensesModal: {
                expenses: 'Splátky SÚ, HÚ a ostatné záväzky',
                closeWindow: 'Zavrieť okno',
                expensesLiving: 'Výdavky na domácnosť',
                expensesInsurance: 'Poistenie',
                expensesLoans: 'Nestresované splátky SÚ (vyjma ref. úveru)',
                sumOfAllBalances: 'Objem nesplatených SÚ (vyjma ref. úveru)',
                expensesOverdraftLimits: 'Limit kontokorentu',
                expensesCreditCardLimits: 'Limit kreditnej karty',
                expensesAlimony: 'Výživné',
                expensesLiability: 'Ručenie',
                monthlyWageDeduction: 'Mesačné zrážky zo mzdy',
                monthlyInsurance: 'Mesačné poistné akéhokoľvek druhu',
                costsForOtherHouseholdPersons: 'Náklady na osoby žijúce v jednej domácnosti',
                mortgageLoans: 'Splátky HÚ (vyjma ref. úveru)',
                sumOfAllMortgagesLoanBalances: 'Objem nesplatených HÚ (vyjma ref. úveru)'
            },
            incomesTSModal: {
                incomesDP: 'Príjem z daňového priznania',
                closeWindow: 'Zavrieť okno',
            },
            incomesOtherModal: {
                otherIncomes: 'Ostatné príjmy',
                closeWindow: 'Zavrieť okno',
                otherIncomesCz: {
                    starobniDuchod: 'Starobný dôchodok',
                    rodicovskyPrispevek: 'Rodičovský/Materské',
                    invalidniDuchod12: 'Čiastočný invalidný dôchodok',
                    invalidniDuchod: 'Plný invalidný dôchodok',
                    dpc: ' ',
                    najemniSmlouva: 'Nájomné',
                    materska: 'Materské',
                    odmenaPestouna: 'Odmena pestúna',
                    vyzivne: 'Výživné',
                    cestovne: 'Diéty',
                    budoucniPronajem: 'Budúci príjem z prenájmu',
                    vysluhaPolicieVojaci: 'Výsluhový dôchodok (polícia a vojaci)',
                    nahradaZtratyVydelku: 'Trvalé náhrady za stratu zárobku',
                    vysluhaHornici: 'Výsluhový dôchodok (baníci)',
                    vdovskyDuchod: 'Vdovský dôchodok',
                    prispevekNaBydleni: 'Príspevok na bývanie alebo stabilizačný príspevok',
                    sirotciDuchod: 'Sirotský dôchodok',
                    prijemZKapitalovehoMajetku: 'Čistý mesačný príjem z kapitálového majetku',
                    prispevekNaOsobniAsistenci: 'Príspevok na osobnú asistenciu',
                    podporaVNezamestnanosti: 'Podpora v nezamestnanosti',
                    vysluha: 'Výsluhový dôchodok',
                    urazovaRenta: 'Úrazová renta',
                    doplnkovyDuchod: 'Doplnkový dôchodok (III. pilier)'
                },
            },
            firstAccessModal: {
                waitAMoment: 'Ešte chvíľočku ...',
                waitAMomentDescription: 'Niektoré údaje na výpočet bonity sú iné, než vo výpočte sadzieb. Prosím vyberte, aké parametre chcete použiť na výpočet bonity.',
                ltvQuestion: 'Aké LTV chcete použiť na výpočet bonity?',
                fixationQestion: 'Akú fixáciu chcete použiť na výpočet bonity?',
                continue: 'Pokračovať',
                ltvFromPrevCalc: 'LTV z predošlého výpočtu',
                maxLtv: 'Max LTV',
                ltvFromRates: 'LTV z kalkulácie sadzieb',
                fixationFromPrevCalc: 'Fixácia z predošlého výpočtu',
                fixationFromRates: 'Fixácia z kalkulácie sadzieb',
                years: {
                    variable: 'Variabilný',
                    oneYear: '1 rok',
                    twoYears: '2 roky',
                    threeYears: '3 roky',
                    fourYears: '4 roky',
                    years: 'rokov'
                }
            },
            discountsAndFeesModal: {
                selectAll: 'Vybrať všetko',
                tradeDiscount: 'Obchodná zľava',
                processingCosts: 'Náklady na spracovanie',
                monthlyCosts: 'Mesačné náklady',
                specificVariants: 'Jednotlivé varianty',
                allVariants: 'Všetky varianty',
                fillCosts: 'Zvoliť poplatok',
                poplatekZaOdhad: 'Poplatok za odhad',
                poplatekZaZpracovani: 'Poplatok za spracovanie',
                poplatekZaCerpani: 'Poplatok za čerpanie',
                poplatekZaCerpaniZS: 'Poplatok za čerpanie na návrh na vklad ZS',
                other: 'Iné',
                poplatekZaSpravu: 'Poplatok za správu úveru',
                poplatekZaVedeni: 'Poplatok za vedenie účtu',
                uverovePojisteni: 'Úverové / Životné poistenie',
                pojisteniNemovitosti: 'Poistenie nehnuteľnosti',
            },
            methodologyFilterModal: {
                title: 'Metodický filter',
                banks: 'Výber bánk',
                parameters: 'Parametre výberu',
                buttons: {
                    showInMethodology: 'Zobraziť v metodike'
                }
            },
            menuModal: {
                home: 'Úvod',
                hypo: 'Hypotekárne',
                personal: '',
                unsecured: '',
                loans: 'úvery',
                methodology: 'Metodika',
                newCalculation: 'Nová kalkulácie',
                savedCalculations: 'Uložené kalkulácie',
                hypoOnline: '',
                manual: 'Návody',
                news: 'Novinky',
                bankList: 'Produktové karty bánk',
                paymentCalendar: 'Splátkový kalendár'
            }
        },
        storedCalc: {
            title: 'Uložené kalkulácie',
            table: {
                modelationTitle: 'Meno klienta / názov kalkulácie',
                modelationDateTime: 'Dátum kalkulácie',
                modelationDateTimeShort: 'Dátum kalkulácie',
                date: 'Datum',
                rates: 'Výpočet sadzieb',
                credit: 'Výpočet úverovej bonity',
                loading: 'Načítam uložené kalkulácie.',
                noCalculations: 'Nemáte žiadne uložené kalkulácie.',
                clientTitle: 'Meno klienta',
                calculationTitle: 'Názov kalkulácie',
                action: 'Akcia',
                delete: 'Zmazať'
            }
        },
        tooltip: {
            credit: {
                app: {
                    rate: 'Úroková sadzba, ktorú by žiadateľ mohol dostať na hypotéku.',
                    maturity: 'Doba, ktorú bude žiadateľ splácať úver. Najčastejšie býva 30 rokov.',
                    loanPurpose: 'Na aký účel si klient berie úver.',
                    ltv: '"Loan to value" Úver k hodnote. Pomer medzi výškou úveru a výškou hodnoty zakladanej nehnuteľnosti.',
                    fixation: 'Doba platnosti úrokovej sadzby, t.j. doba počas ktorej sa úroková sadzba nemení.',
                    propertyType: 'O akú nehnuteľnosť sa jedná',
                    refinMortgageBalance: ' ',
                    refinMortgageIncrease: ' ',

                    loanAmount: 'Koľko peňazí si klient potrebuje požičať',
                    reinsurance: 'Hodnota nehnuteľnosti, ktorou klient dokáže zabezpečiť úver. Hodnota je určená znaleckým posudkom a banka je oprávnená ju prehodnotiť. V niektorých prípadoch hodnotu môže stanoviť samotná banka (väčšinou pri bytoch).',
                    age: 'Vek najstaršieho zo žiadateľov o úver.',
                    ratesIncomes: 'Hlavný príjem hlavného žiadateľa slúži pre výpočet úrokových sadzieb.'
                },
                resultTable: {
                    fixation: 'Doba platnosti úrokovej sadzby',
                    mothlyExpenses: 'Celková výška mesačných poplatkov mimo splátky úveru',
                    processingCosts: 'Celkové poplatky za vybavenie úveru',
                    financialCostPerYear: 'Priemerné ročné náklady na úver počas prvej fixácie - zaplatená suma na úrokoch a poplatkoch (po odpočítaní výnosu) za každý rok prvý fixácie.',
                    rpsn: 'Ročná percentuálna miera nákladov vyjadruje reálne percento nákladov spojených s hypotékou, t.j. koľko z požičanej čiastky zaplatí klient banke nielen na istine a úrokoch ale aj na ďalších poplatkoch za 1 rok.',
                    properties: 'Ostatné vlastnosti hypotéky'
                },
                household: {
                    familyStatus: 'Rodinný stav žiadateľa o úver.',
                    county: 'Oblasť trvalého bydliska žiadateľa.',
                },
                applicant: {
                    name: 'Meno žiadateľa',
                    lastname: 'Priezvisko žiadateľa',
                    vek: 'Vek žiadateľa.',
                    incomes: {
                        monthlyNetIncome: 'Čistý mesačný príjem zo zamestnania žiadateľa.'
                    },
                    expenses: {
                        loans: 'V prípade splátky SÚ zadajte súčasnú - reálnu splátku, ktorú platí klient. Pri HÚ je potrebné splátku "ostresovať", t.j. súčasnú splátku HÚ navýšiť o 2%. Prepočítať si splátku viete urobiť v module "Splátkový kalendár".'
                    }
                },
                incomesOtherModal: {
                    otherIncomesCz: {
                        starobniDuchod: 'Mesačná výška starobného dôchodku.',
                        rodicovskyPrispevek: 'NEZABUDNI, vyplniť počet detí v module bonita. Materské akceptované do výšky RD.',
                        invalidniDuchod12: 'Mesačná výška čiastočného invalidného dôchodku.',
                        invalidniDuchod: 'Mesačná výška plného invalidného dôchodku.',
                        dpc: ' ',
                        najemniSmlouva: ' ',
                        materska: 'Mesačná výška peňažnej pomoci v materstve (vypláca sa v súvislosti s tehotenstvom, materstvom a starostlivosti o novorodenca), rodičia najskôr čerpajú materskú, potom rodičovský príspevok, do počtu osôb musí byť vždy zahrnuté deti, na ktoré je poberané materské.',
                        odmenaPestouna: 'Mesačná výška odmien pestúna.',
                        vyzivne: 'Mesačná výška výživného (alimentov), ktoré rodič dostáva, do počtu osôb musí byť vždy zahrnuté deti, na ktoré je poberané výživné.',
                        cestovne: 'Mesačná výška diét (cestovných náhrad).',
                        budoucniPronajem: 'Mesačný príjem z budúceho prenájmu.',
                        vysluhaPolicieVojaci: 'Mesačná výška výsluhového dôchodku (renty) policajtov a vojakov.',
                        nahradaZtratyVydelku: 'Mesačná výška trvalých náhrad za stratu príjmu úrazom alebo z choroby.',
                        vysluhaHornici: 'Mesačná výška výsluhového dôchodku (renty) baníkov.',
                        vdovskyDuchod: 'Mesačná výška vdovského dôchodku.',
                        prispevekNaBydleni: 'Mesačná výška príspevku na bývanie a stabilizačného príspevku pre vojakov.',
                        sirotciDuchod: 'Mesačná výška sirotského dôchodku.',
                        prijemZKapitalovehoMajetku: 'Mesačný čistý príjem z kapitálového majetku.',
                        prispevekNaOsobniAsistenci: 'Mesačná výška príspevku na osobnú asistenciu.',
                        podporaVNezamestnanosti: 'Mesačná výška dávky v nezamestnanosti.',
                        vysluha: 'Mesačná výška výsluhového dôchodku (renta - vojaci, policajti, baníci).',
                        urazovaRenta: 'Mesačná výška trvalých náhrad za stratu príjmov z dôvodu úrazu alebo choroby.',
                        doplnkovyDuchod: 'Mesačná výška dôchodku z III. piliera.'
                    },
                },
                expensesModal: {
                    expensesLiving: 'Mesačné výdavky na domácnosť (spotreba, potraviny, atď.).',
                    expensesInsurance: 'Celkové mesačné výdavky na poistenie (životné, majetok, auto,...).',
                    expensesLoans: 'Celkové mesačné splátky úverov.',
                    sumOfAllBalances: 'Celkový objem všetkých nesplatených úverov.',
                    expensesOverdraftLimits: 'Aktuálny limit kontokorentu na účtoch žiadateľov o úver.',
                    expensesCreditCardLimits: 'Aktuálne limity kreditných kariet na účtoch žiadateľov o úver.',
                    expensesAlimony: 'Celkové mesačné výdaje na vyživované deti.',
                    expensesLiability: 'Celkové mesačné výdaje na platby ručiteľských záväzkov.',
                    monthlyWageDeduction: 'Všetky mesačné zrážky zo mzdy.',
                    monthlyInsurance: 'Platby za všetky poistenia spolu.',
                    costsForOtherHouseholdPersons: 'Náklady na osoby žijúce v spoločnej domácnosti.',
                    mortgageLoans: 'Splátku je nutné stresovat',
                    sumOfAllMortgagesLoanBalances: 'Celková suma neuhradených HÚ'
                }
            },
            storedCalc: {
                table: {
                    deleteClient: 'Odstrániť klienta',
                    deleteModelation: 'Odstrániť modeláciu',
                    ratesModelation: 'Prejsť na výpočet sadzby',
                    ratesPdf: 'Export do PDF s výsledkami v čase uloženia',
                    creditModelation: 'Prejsť na výpočet úverovej bonity',
                    creditPdf: 'Export do PDF s výsledkami v čase uloženia'
                }
            }
        },
        error: {
            couldNotConnectToBackend: 'Nepodarilo sa pripojiť k backend aplikácie. Pokiaľ bude problém pretrvávať, prosím kontaktujte podporu pomocou kontaktného formulára.',
            couldNotFindMethodologyItem: 'Položku sa nepodarilo nájsť v metodike. Pokiaľ bude problém pretrvávať, prosím kontaktujte podporu pomocou kontaktného formulára.',
            loanAmountTooHigh: 'Výška úveru nesmie byť vyššia ako 90 % hodnoty zaistenia'
        }
    }
}

export { localeMessages };