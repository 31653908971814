<template>
    <div class="modal-body">
        <div class="bordered-group">
            <div class="form-check">
                <label class="clickable">
                    <input type="checkbox" id="incomesBusinessFlatRate" v-model="applicant.activeSections.incomes.incomeTaxFlatRate.incomeCheckboxes.prijmyPodnikani" @click="collapse" /> Příjmy z podnikání
                </label>
                <img class="group-reset"
                     src="@/assets/icons/update-auto.svg"
                     @click="resetIncomesBusiness"
                     v-bTooltip="$t('global.defaultValues')" />
            </div>
            <div class="collapse" id="incomesBusinessFlatRateContent">
                <div class="bordered-group-inner">

                    <div class="row">

                        <div class="col-md-6">
                            <v-labeled-button-select label="Paušál"
                                                     :value="applicant.incomes.taxFlatRateCz.taxFlatRateBusinessCz.flatRateCz"
                                                     @update:value="val => applicant.incomes.taxFlatRateCz.taxFlatRateBusinessCz.flatRateCz = val"
                                                     :items="flatRates"
                                                     selectId="flatRateTaxPercentage" />
                        </div>

                        <div class="col-md-6">
                            <v-labeled-select-list selectName="czNace"
                                                   label="Druh činnosti (CZ-NACE)"
                                                   v-model="applicant.incomes.taxFlatRateCz.taxFlatRateBusinessCz.czNaceCz"
                                                   :items="czNaces" />
                        </div>


                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-6">
                                    <v-labeled-input label="ř. 101 - Příjmy z podnikání"
                                                     v-model="applicant.incomes.taxFlatRateCz.taxFlatRateBusinessCz.businessIncome"
                                                     number="true"
                                                     :addonString="$t('global.currencySymbol')"
                                                     inputId="incomesFlatRateBusiness"
                                                     inputTooltip="Byly přesaženy max. hodnoty"
                                                     inputTooltipTrigger="manual"
                                                     groupClass="flatRateBussinesIncomeGroup">
                                    </v-labeled-input>
                                </div>
                                <div class="col-md-6">
                                    <v-labeled-button-select label="Uzavřená zdaňovací období"
                                                             :value="applicant.incomes.taxFlatRateCz.taxFlatRateBusinessCz.closedTaxPeriod"
                                                             @update:value="val => applicant.incomes.taxFlatRateCz.taxFlatRateBusinessCz.closedTaxPeriod = val"
                                                             :items="closedTaxPeriods"
                                                             selectId="closedTaxPeriod">
                                    </v-labeled-button-select>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12" style="margin-top: 10px;">
                            <div class="row">
                                <div class="col-md-6" style="max-height: 60px;">
                                    <v-labeled-select-list selectName="transactionsOneCustomer"
                                                           label="HB 80% transakcí od 1 odběratele"
                                                           v-model="applicant.incomes.taxFlatRateCz.taxFlatRateBusinessCz.transactionsOneCustomer"
                                                           :items="[{name: 'Ano', value: true}, {name: 'Ne', value: false}]"
                                                           :searchable="true">
                                    </v-labeled-select-list>
                                </div>
                                <!--<div class="col-md-6  empty-form-group"></div>-->
                                <div class="col-md-6">
                                    <v-labeled-input label="Kreditní obrat za 12 měsíců (jen pro výpočet KB)"
                                                     v-model="applicant.incomes.taxFlatRateCz.taxFlatRateBusinessCz.creditTurnover12Months"
                                                     number="true"
                                                     :addonString="$t('global.currencySymbol')"
                                                     inputId="creditTurnover12Months"
                                                     inputTooltip="Vyplňte kreditní obrat/celkové příjmy žadatele za posledních 12 měsíců. Tato hodnota slouží pouze pro výpočet příjmu u Komerční banky. Rovněž je třeba zadat prohlášený příjem klienta dle Čestného prohlášení z výše příjmů do kolonky pro ř. 101.">
                                    </v-labeled-input>
                                </div>
                                <!--<div class="col-md-6  empty-form-group"></div>-->
                                <div class="col-md-6">
                                    <v-labeled-input label="Kreditní obrat za 1. měsíc"
                                                     v-model="applicant.incomes.taxFlatRateCz.taxFlatRateBusinessCz.creditTurnover1Month"
                                                     number="true"
                                                     :addonString="$t('global.currencySymbol')"
                                                     inputId="creditTurnover1Month">
                                    </v-labeled-input>
                                </div>
                                <div class="col-md-6">
                                    <v-labeled-input label="Suma nezap. položek"
                                                     v-model="applicant.incomes.taxFlatRateCz.taxFlatRateBusinessCz.uncreditedAmount1Month"
                                                     number="true"
                                                     :addonString="$t('global.currencySymbol')"
                                                     inputId="uncreditedAmount1Month"
                                                     :inputTooltip="uncreditedAmountTooltip">
                                    </v-labeled-input>
                                </div>
                                <div class="col-md-6">
                                    <v-labeled-input label="Kreditní obrat za 2. měsíc"
                                                     v-model="applicant.incomes.taxFlatRateCz.taxFlatRateBusinessCz.creditTurnover2Month"
                                                     number="true"
                                                     :addonString="$t('global.currencySymbol')"
                                                     inputId="creditTurnover2Month">
                                    </v-labeled-input>
                                </div>
                                <div class="col-md-6">
                                    <v-labeled-input label="Suma nezap. položek"
                                                     v-model="applicant.incomes.taxFlatRateCz.taxFlatRateBusinessCz.uncreditedAmount2Month"
                                                     number="true"
                                                     :addonString="$t('global.currencySymbol')"
                                                     inputId="uncreditedAmount2Month"
                                                     :inputTooltip="uncreditedAmountTooltip">
                                    </v-labeled-input>
                                </div>
                                <div class="col-md-6">
                                    <v-labeled-input label="Kreditní obrat za 3. měsíc"
                                                     v-model="applicant.incomes.taxFlatRateCz.taxFlatRateBusinessCz.creditTurnover3Month"
                                                     number="true"
                                                     :addonString="$t('global.currencySymbol')"
                                                     inputId="creditTurnover3Month">
                                    </v-labeled-input>
                                </div>
                                <div class="col-md-6">
                                    <v-labeled-input label="Suma nezap. položek"
                                                     v-model="applicant.incomes.taxFlatRateCz.taxFlatRateBusinessCz.uncreditedAmount3Month"
                                                     number="true"
                                                     :addonString="$t('global.currencySymbol')"
                                                     inputId="uncreditedAmount3Month"
                                                     :inputTooltip="uncreditedAmountTooltip">
                                    </v-labeled-input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="bordered-group">
            <div class="form-check">
                <label class="clickable">
                    <input type="checkbox" id="incomesRentingFlatRate" v-model="applicant.activeSections.incomes.incomeTaxFlatRate.incomeCheckboxes.prijmyPronajem" @click="collapse" /> Příjmy z pronájmu
                </label>
                <img class="group-reset"
                     src="@/assets/icons/update-auto.svg"
                     @click="resetIncomesRenting"
                     v-bTooltip="$t('global.defaultValues')" />
            </div>
            <div class="collapse" id="incomesRentingFlatRateContent">

                <div class="bordered-group-inner">
                    <div class="row">
                        <div class="col-md-4">
                            <v-labeled-button-select label="Paušál"
                                                     :items="[{name: 'ANO', value: true}, {name: 'NE', value: false}]"
                                                     :value="applicant.incomes.taxFlatRateCz.taxFlatRateLeaseCz.leaseFlatRate"
                                                     @update:value="val => applicant.incomes.taxFlatRateCz.taxFlatRateLeaseCz.leaseFlatRate = val"
                                                     selectId="FlatRateleaseFlatRate"
                                                     @change="leaseFlatRateChanged" />
                        </div>
                    </div>
                    <div class="hypo-form-group">
                        <h3>Minulé období</h3>
                    </div>
                    <div class="row">
                        <div class="col-md-5">
                            <v-labeled-input label="ř. 201 - Příjmy z pronájmu (max. 50 000 Kč)"
                                             v-model="applicant.incomes.taxFlatRateCz.taxFlatRateLeaseCz.leaseIncome"
                                             number="true"
                                             :addonString="$t('global.currencySymbol')"
                                             inputId="incomesFromRentFLatRate"
                                             @blured="leaseBlured"
                                             inputTooltip="Byly přesaženy max. hodnoty"
                                             inputTooltipTrigger="manual"
                                             groupClass="flatRateLeaseIncomeGroup"
                                             numberMax="50000">

                            </v-labeled-input>
                        </div>
                        <div class="col-md-5">
                            <v-labeled-input label="ř. 39 - Základ daně z pronájmu"
                                             v-model="applicant.incomes.taxFlatRateCz.taxFlatRateLeaseCz.leaseTaxBase"
                                             number="true" minus="true"
                                             :addonString="$t('global.currencySymbol')"
                                             :iconDef="inputIcon(applicant.incomes.taxFlatRateCz.taxFlatRateLeaseCz.leaseFlatRate, applicant.activeSections.incomes.incomeTaxFlatRate.disabledInputs.leaseTaxBaseInputs.lastLease)"
                                             :overrideValue="autoloadPronajemZaklad"
                                             :disabledInput="applicant.activeSections.incomes.incomeTaxFlatRate.disabledInputs.leaseTaxBaseInputs.lastLease && applicant.incomes.taxFlatRateCz.taxFlatRateLeaseCz.leaseFlatRate"
                                             :iconTooltip="applicant.activeSections.incomes.incomeTaxFlatRate.disabledInputs.leaseTaxBaseInputs.lastLease ? 'Upravit' : 'Vypočítat'"
                                             inputId="incomesFromRentTaxBaseFLatRate"
                                             @overrideClicked="applicant.activeSections.incomes.incomeTaxFlatRate.disabledInputs.leaseTaxBaseInputs.lastLease = false"
                                             @overrideHandler="setLastLeaseHandler">
                            </v-labeled-input>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import LabeledButtonSelect from '../LabeledButtonSelect.vue'
    import LabeledSelectList from '../LabeledSelectList.vue'
    import LabeledInput from '../LabeledInput.vue'
    import { ResetFlatRateTax, ResetFlatRateTaxBusiness, ResetFlatRateTaxLease } from '../../js/HouseholdHelpers.mjs'

    export default {
        name: 'v-incomes-flat-rate-tax-body',
        props: {
            tsProvider: String,
            applicant: Object,
            flatRates: Array,
            czNaces: Array,
            closedTaxPeriods: Array
        },
        data() {
            return {
                taxBaseDisabledInputs: {
                    Lease: true
                },
                overrideSetterFunctions: {
                    Lease: null
                },
                uncreditedAmountTooltip: 'Nezapočitatelnými položkami se rozumí následující: příchozí transakce z jiných vlastních účtů klienta a jednorázové transakce nesouvisející s podnikáním jako je zejména načerpání úvěru, příjem z prodeje nemovitosti, z prodeje cenných papírů, příjem z dědictví apod.'
            }
        },
        computed: {
        },
        methods: {
            collapse(event) {
                /* eslint-disable */
                var id = event.currentTarget.id;
                var checkInput = document.getElementById(id);
                if (checkInput.checked) $('#' + id + 'Content').collapse('show');
                else $('#' + id + 'Content').collapse('hide');
            },
            resetIncomesBusiness() {
                ResetFlatRateTaxBusiness(this);
            },
            resetIncomesRenting() {
                ResetFlatRateTaxLease(this);
                this.disableLeaseTaxBaseInput();
            },
            manageCollapses: function () {
                /* eslint-disable */
                if (this.applicant.activeSections.incomes.incomeTaxFlatRate.incomeCheckboxes.prijmyPodnikani) $('#incomesBusinessFlatRateContent').collapse('show');
                else $('#incomesBusinessFlatRateContent').collapse('hide');
                if (this.applicant.activeSections.incomes.incomeTaxFlatRate.incomeCheckboxes.prijmyPronajem) $('#incomesRentingFlatRateContent').collapse('show');
                else $('#incomesRentingFlatRateContent').collapse('hide');
            },
            resetModal() {
                ResetFlatRateTax(this);
                this.disableLeaseTaxBaseInput();
                this.manageCollapses();
            },
            disableLeaseTaxBaseInput() {
                this.applicant.activeSections.incomes.incomeTS.disabledInputs.leaseTaxBaseInputs.lastLease = true;
            },
            leaseFlatRateChanged(value) {
                if (value) {
                    this.$nextTick(() => {
                        this.overrideSetterFunctions.Lease();
                    })
                }
            },
            leaseBlured() {
                if (this.applicant.incomes.taxFlatRateCz.taxFlatRateLeaseCz.leaseFlatRate)
                    this.overrideSetterFunctions.Lease();
            },
            setLastLeaseHandler(fn) {
                this.overrideSetterFunctions.Lease = fn;
            },
            leaseIsFlatRate: function () {
                return this.applicant.incomes.taxFlatRateCz.taxFlatRateLeaseCz.leaseFlatRate;
            },
            autoloadPronajemZaklad: function () {
                if (this.leaseIsFlatRate) {
                    return this.countLeaseIncomeBase(this.applicant.incomes.taxFlatRateCz.taxFlatRateLeaseCz.leaseIncome);
                }
                return null;
            },
            countLeaseIncomeBase(income) {
                var vydaj = income * 0.3;
                if (vydaj > 300000) vydaj = 300000;
                return Math.round(income - vydaj);
            },
            inputIcon(isFlatRate, isDisabled) {
                if (isFlatRate) {
                    if (isDisabled) return "fas fa-edit";
                    return null
                }
                return null;
            }
        },
        mounted() {
            /* eslint-disable */
            this.observer = new MutationObserver(mutations => {
                for (const m of mutations) {
                    const newValue = m.target.className;
                    if (newValue === 'modal fade in') {
                        this.$nextTick(() => {
                            this.manageCollapses();
                        });
                    }
                }
            });

            $(document).on('hidden.bs.modal', '.modal', function () {
                $('.modal:visible').length && $(document.body).addClass('modal-open');
            });

            this.observer.observe(this.$parent.$refs.incomesTSModal, {
                attributes: true,
                attributeFilter: ['class'],
            });
            this.manageCollapses();
        },
        components: {
            'v-labeled-button-select': LabeledButtonSelect,
            'v-labeled-select-list': LabeledSelectList,
            'v-labeled-input': LabeledInput,
        }

    }
</script>

<style scoped src="./IncomesBodyStyle.css"></style>

