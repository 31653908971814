<template>
    <tr class="active resultItemRow">
        <td class="lh18">
            <a target="_blank" :href="'/Poskytovatel/' + item.BankCode"
               @click="gtmTrackClickUI('Detail partnera', {partner: item.BankCode});">
                <div class="smallImgWrapper">
                    <img v-bTooltip="item.ProductName" :src="'/ImageProxy.aspx?f=' + item.BankCode + '-big.png'" />
                </div>
            </a>
        </td>
        <template v-if="item.BankCode == 'KB'"> <!--Přidána dočasná výjimka pro KB. Pro odstranění smazat celý tento 'v-if template' a následující 'v-else template' ponechat obsah-->
            
                <td class="text-center" colspan="5">
                    <span class="">Výpočet bonity dočasně pozastaven</span>
                </td>
                
                <td class="text-right">
                    <vue-numeric v-if="item.MaxAmount > 0" :currency="$t('global.currencySymbol')" currency-symbol-position="suffix" separator="space" read-only="true" v-model="item.AppliedIncomeTotal"></vue-numeric>
                </td>
                <td class="text-center">
                    <span class="income-icons" style="font-size: 17px;">
                        <img :class="{'gray': !item.AppliedEmployeeIncome}" src="@/assets/icons/prijmy_zavisla_cinnost.svg" v-bTooltip="item.AppliedEmployeeIncome ? $t('credit.resultTableItem.AppliedEmployeeIncome') + item.AppliedEmployeeIncome : $t('credit.resultTableItem.AppliedEmployeeIncome')" @mouseleave="hideAllTooltips" />
                        <img :class="{'gray': !item.AppliedBusinessIncome}" src="@/assets/icons/prijmy_podnikani.svg" v-bTooltip="item.AppliedBusinessIncome ? $t('credit.resultTableItem.AppliedBusinessIncome') + item.AppliedBusinessIncome : $t('credit.resultTableItem.AppliedBusinessIncome')" @mouseleave="hideAllTooltips" />
                        <img :class="{'gray': !item.AppliedBusinessIncomeDph}" src="@/assets/icons/prijmy_obrat.svg" v-bTooltip="item.AppliedBusinessIncomeDph ? $t('credit.resultTableItem.AppliedBusinessIncomeDph') + item.AppliedBusinessIncomeDph : $t('credit.resultTableItem.AppliedBusinessIncomeDph')" @mouseleave="hideAllTooltips" />
                        <img :class="{'gray': !item.AppliedLeaseIncome}" src="@/assets/icons/prijmy_pronajem.svg" v-bTooltip="item.AppliedLeaseIncome ? $t('credit.resultTableItem.AppliedLeaseIncome') + item.AppliedLeaseIncome : $t('credit.resultTableItem.AppliedLeaseIncome')" @mouseleave="hideAllTooltips" />
                        <img :class="{'gray': !item.AppliedOtherIncome}" src="@/assets/icons/prijmy_ostatni.svg" v-bTooltip="item.AppliedOtherIncome ? $t('credit.resultTableItem.AppliedOtherIncome') + item.AppliedOtherIncome : $t('credit.resultTableItem.AppliedOtherIncome')" @mouseleave="hideAllTooltips" />
                        <img :class="{'gray': !item.AppliedFlatRateIncome}" src="@/assets/icons/prijmy_pausal.svg" v-bTooltip="item.AppliedFlatRateIncome ? $t('credit.resultTableItem.AppliedFlatRateIncome') + item.AppliedFlatRateIncome : $t('credit.resultTableItem.AppliedFlatRateIncome')" @mouseleave="hideAllTooltips" />
                    </span>
                </td>

        </template>
        <template v-else>
            <td class="text-right" v-if="this.item.MaxAmount > 0">
                <b>
                    <vue-numeric :currency="$t('global.currencySymbol')" currency-symbol-position="suffix" separator="space" read-only="true" v-model="itemMaxAmount"></vue-numeric>
                </b>
            </td>
            <td class="text-right" v-else>
                <span>{{ $t('credit.resultTableItem.loanCant') }}</span>
            </td>

            <td class="text-right" v-if="itemMaturityMonths == 0">
                <vue-numeric :currency="$t('global.years')" v-if="item.Maturity > 0 && this.item.MaxAmount > 0" currency-symbol-position="suffix" separator="space" read-only="true" v-model="itemMaturityYears"></vue-numeric>
            </td>
            <td v-else class="bonitaMaturityColumn text-right">
                <vue-numeric :currency="$t('global.years')" v-if="item.Maturity > 0 && this.item.MaxAmount > 0" currency-symbol-position="suffix" separator="space" read-only="true" v-model="itemMaturityYears"></vue-numeric><br />
                <vue-numeric :currency="$t('global.month')" v-if="item.Maturity > 0 && this.item.MaxAmount > 0 && itemMaturityMonths == 1" currency-symbol-position="suffix" separator="space" read-only="true" v-model="itemMaturityMonths"></vue-numeric>
                <vue-numeric :currency="$t('global.months2')" v-if="item.Maturity > 0 && this.item.MaxAmount > 0 && itemMaturityMonths >= 2 && itemMaturityMonths < 5" currency-symbol-position="suffix" separator="space" read-only="true" v-model="itemMaturityMonths"></vue-numeric>
                <vue-numeric :currency="$t('global.months')" v-if="item.Maturity > 0 && this.item.MaxAmount > 0 && itemMaturityMonths >= 5" currency-symbol-position="suffix" separator="space" read-only="true" v-model="itemMaturityMonths"></vue-numeric>
            </td>
            <td class="text-right">
                <vue-numeric :currency="$t('global.currencySymbol')" v-if="item.MaxAmount > 0" currency-symbol-position="suffix" separator="space" read-only="true" v-model="itemInstallment"></vue-numeric>
            </td>

            <template v-if="isMoneta">
                <td class="text-center" colspan="4">
                    <span class="badgeOverenoOnline badge">{{ $t('credit.resultTableItem.onlineApproved') }}</span>
                </td>
            </template>
            <template v-else>
                <td class="text-center">
                    <vue-numeric v-if="item.MaxAmount > 0" decimal-separator="," precision="2" read-only="true" v-model="itemDti"></vue-numeric>
                </td>
                <td class="text-center">
                    <vue-numeric v-if="item.MaxAmount > 0" decimal-separator="," precision="2" read-only="true" v-model="itemDsti" currency="%" currency-symbol-position="suffix"></vue-numeric>
                </td>
                <td class="text-right">
                    <vue-numeric v-if="item.MaxAmount > 0" :currency="$t('global.currencySymbol')" currency-symbol-position="suffix" separator="space" read-only="true" v-model="item.AppliedIncomeTotal"></vue-numeric>
                </td>
                <td class="text-center">
                    <span class="income-icons" style="font-size: 17px;">
                        <img :class="{'gray': !item.AppliedEmployeeIncome}" src="@/assets/icons/prijmy_zavisla_cinnost.svg" v-bTooltip="item.AppliedEmployeeIncome ? $t('credit.resultTableItem.AppliedEmployeeIncome') + item.AppliedEmployeeIncome : $t('credit.resultTableItem.AppliedEmployeeIncome')" @mouseleave="hideAllTooltips" />
                        <img :class="{'gray': !item.AppliedBusinessIncome}" src="@/assets/icons/prijmy_podnikani.svg" v-bTooltip="item.AppliedBusinessIncome ? $t('credit.resultTableItem.AppliedBusinessIncome') + item.AppliedBusinessIncome : $t('credit.resultTableItem.AppliedBusinessIncome')" @mouseleave="hideAllTooltips" />
                        <img :class="{'gray': !item.AppliedBusinessIncomeDph}" src="@/assets/icons/prijmy_obrat.svg" v-bTooltip="item.AppliedBusinessIncomeDph ? $t('credit.resultTableItem.AppliedBusinessIncomeDph') + item.AppliedBusinessIncomeDph : $t('credit.resultTableItem.AppliedBusinessIncomeDph')" @mouseleave="hideAllTooltips" />
                        <img :class="{'gray': !item.AppliedLeaseIncome}" src="@/assets/icons/prijmy_pronajem.svg" v-bTooltip="item.AppliedLeaseIncome ? $t('credit.resultTableItem.AppliedLeaseIncome') + item.AppliedLeaseIncome : $t('credit.resultTableItem.AppliedLeaseIncome')" @mouseleave="hideAllTooltips" />
                        <img :class="{'gray': !item.AppliedOtherIncome}" src="@/assets/icons/prijmy_ostatni.svg" v-bTooltip="item.AppliedOtherIncome ? $t('credit.resultTableItem.AppliedOtherIncome') + item.AppliedOtherIncome : $t('credit.resultTableItem.AppliedOtherIncome')" @mouseleave="hideAllTooltips" />
                        <img :class="{'gray': !item.AppliedFlatRateIncome}" src="@/assets/icons/prijmy_pausal.svg" v-bTooltip="item.AppliedFlatRateIncome ? $t('credit.resultTableItem.AppliedFlatRateIncome') + item.AppliedFlatRateIncome : $t('credit.resultTableItem.AppliedFlatRateIncome')" @mouseleave="hideAllTooltips" />
                    </span>
                </td>

            </template>
        </template>
        
        <td class="text-right">
            <button type="button"
                    class="btn btn-default-gray"
                    @click="linkToChart"
                    v-bTooltip="$t('credit.resultTableItem.gotoPaymentChart')">
                <img class="chart left" src="@/assets/icons/splatky-graf.svg" /> {{ $t('credit.resultTable.buttons.chart') }}
            </button>
        </td>
        <td>
            <a href="item.BankOnlineContractLink"
               @click="gtmTrackClickUI('Sjednání', {partner: item.BankCode});"
               v-if="showOnlineContractLink"
               class="btn btn-primary btn-arrange"
               target="_blank"
               v-bTooltip="$t('credit.resultTableItem.gotoBankOnlineContractSite') +' ' + item.BankCode">{{ $t('credit.resultTable.buttons.arrange') }}</a>
            <div v-if="dorisMortgagesAllowed && item.BankCode == 'HB'" class="dropdown">
                <button type="button"
                        class="btn btn-primary btn-arrange dropdown-toggle"
                        data-toggle="dropdown"
                        aria-expanded="false"
                        v-bTooltip="$t('credit.resultTableItem.gotoBankOnlineContractSite') +' ' + item.BankCode">
                    {{ $t('credit.resultTable.buttons.arrange') }} <img class="mr-6" src="@/assets/icons/downFill.svg" />
                </button>
                <ul class="dropdown-menu dropdown-menu-right">
                    <li>
                        <a :href="pricingOfferLink" :target="dorisLinkTarget"
                           @click="gtmTrackClickUI('Sjednání cenové nabídky', {partner: item.BankCode});">Cenová nabídka</a>
                    </li>
                    <li>
                        <a href="/Doris?login=true&redirectTo=/Mortgages/cz/Application/CreateNewApplication/HB" :target="dorisLinkTarget"
                           @click="gtmTrackClickUI('Sjednání online žádost', {partner: item.BankCode});">Online žádost</a>
                    </li>
                </ul>
            </div>
        </td>
    </tr>
</template>

<script>
    export default {
        name: 'v-result-table-item',
        props: {
            item: Object,
            calcInProgress: Boolean,
            loanParams: Object,
            showLoanScheduleLink: Boolean,
            limitlessResult: Boolean,
            dorisMortgagesAllowed: Boolean,
            dorisLinkTarget: String
        },
        computed: {
            vyvojHrefLink: function () {
                return '/Chart?vyse=' + this.item.MaxAmount
                    + '&fixace=' + this.loanParams.fixation
                    + '&produkt=' + this.item.ProductName
                    + '&delka=' + this.item.Maturity
                    + '&sazba=' + this.loanParams.rate.toString().replace('.', ',')
                    + '&poskytovatel=' + this.item.BankCode
                    + '&disableProdukt=true';
            },
            pricingOfferLink() {
                return '/Doris.aspx?action=newPricingOffer&code=HB&rate=' + this.loanParams.rate.toString().replace('.', ',')
                    + '&fixation=' + this.loanParams.fixation
                    + '&maturity=' + this.loanParams.maturity;
            },
            isMoneta: function () {
                return this.item.BankCode === 'MMB';
            },
            showOnlineContractLink: function () {
                return this.item.BankOnlineContractLink != null && this.item.BankOnlineContractLink != "" && this.item.ShowOnlineContractLinkInCreditWorthiness;
            },
            itemDsti: function () {
                return this.limitlessResult && this.item.LimitlessResult.MaxAmount > 0 ? this.item.LimitlessResult.Dsti * 100 : this.item.Dsti * 100;
            },
            itemDti: function () {
                return this.limitlessResult && this.item.LimitlessResult.MaxAmount > 0 ? this.item.LimitlessResult.Dti : this.item.Dti;
            },
            itemMaxAmount: function () {
                return this.limitlessResult && this.item.LimitlessResult.MaxAmount > 0 ? this.item.LimitlessResult.MaxAmount : this.item.MaxAmount;
            },
            itemInstallment: function () {
                return this.limitlessResult && this.item.LimitlessResult.MaxAmount > 0 ? this.item.LimitlessResult.Installment : this.item.Installment;
            },
            itemMaturityYears() {
                return Math.floor(parseInt(this.item.Maturity / 12));
            },
            itemMaturityMonths() {
                return parseInt(this.item.Maturity) % 12;
            },
        },
        methods: {
            linkToChart() {
                this.gtmTrackClickUI('Splátkový kalendář', {partner: this.item.BankCode});
                window.open(this.vyvojHrefLink, '_blank');
            },
            hideAllTooltips() {
                $(".tooltip").tooltip("hide");
            }
        }
    }
</script>

<style scoped>
    .income-icons > img {
        width: 20px;
        margin: 2px;
        filter: invert(37%) sepia(29%) saturate(2435%) hue-rotate(185deg) brightness(99%) contrast(88%);
    }

    .income-icons > img.gray {
        filter: invert(92%) sepia(0%) saturate(18%) hue-rotate(243deg) brightness(84%) contrast(91%) !important;
    }

    span.income-icons > i {
        padding-right: 5px;
    }

    span.badgeOverenoOnline {
        text-transform: uppercase;
        background-color: rgba(232, 242, 255, 1);
        color: #000;
        padding: 5px 10px;
        font-weight: 600;
    }

    tr.textGreenColor {
        color: LimeGreen !important;
        font-weight: bold;
    }
    span#linkToBankOnlinePortal {
        padding-right: 10px;
    }
    .fa-signature {
        color: #fff;
        background-color: #5cb85c;
        border-color: #4cae4c;
        padding: 3px;
        border-radius: 3px;
    }
    .bonitaMaturityColumn {
        padding: 1px 8px;
    }

    tr {
        white-space: nowrap;
    }
</style>

<style>
    .lh18 {
        line-height: 1.8 !important;
    }
</style>