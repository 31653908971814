<template>
    <div class="modal" id="menuModal" tabindex="-1">
        <div class="modal-dialog modal-lg">
            <div class="modal-content" style="background-color: #f5f5f5">
                <div class="menu-head">
                    <button type="button" class="btn btn-default btn-rounded" @click="redirectToDashboard">
                        <img src="@/assets/icons/obytna.svg" />
                        {{ $t('credit.menuModal.home') }}
                    </button>
                    <button type="button" class="btn-empty" data-dismiss="modal" aria-label="Close">
                        <img class="circle-image" src="/CreditWorthiness/img/X-white.svg" />
                    </button>
                </div>

                <ul class="menu-module-select">
                    <li :class="{ active: hypoActive}" @click="activateModule('Hypo')">
                        <img src="@/assets/icons/domy.svg" /><br />
                        <span class="bold">{{ $t('credit.menuModal.hypo') }}</span><br />
                        {{ $t('credit.menuModal.loans') }}
                    </li>
                    <li v-if="dorisPersonalLoansAllowed" :class="{ active: suActive}" @click="activateModule('SU')">
                        <img src="@/assets/icons/uvery.svg" />
                        <span class="bold">{{ $t('credit.menuModal.personal') }}</span>
                        {{ $t('credit.menuModal.loans') }}
                    </li>
                    <li v-if="dorisUnsecuredLoansAllowed" :class="{ active: nuActive}" @click="activateModule('NU')">
                        <img src="@/assets/icons/domek.svg" />
                        <span class="bold">{{ $t('credit.menuModal.unsecured') }}</span>
                        {{ $t('credit.menuModal.loans') }}
                    </li>
                </ul>

                <div v-if="hypoActive">
                    <div class="menu-link-group">
                        <a :href="'/Doris?login=true&redirectTo=/Methodology/' + localization + '/Mortgage/'"
                           target="dorisLinkTarget">
                            <img src="@/assets/icons/crm.svg" />
                            {{ $t('credit.menuModal.methodology') }}
                        </a>
                        <a href="/?clear=true">
                            <img src="@/assets/icons/sazby.svg" />
                            {{ $t('credit.menuModal.newCalculation') }}
                        </a>
                    </div>
                    <div class="menu-link-group">
                        <a :href="chartUrl">
                            <img src="@/assets/icons/prijmy_pausal.svg" />
                            {{ $t('credit.menuModal.paymentCalendar') }}
                        </a>
                    </div>
                    <div class="menu-link-group">
                        <a href="/StoredCalculations.aspx">
                            <img src="@/assets/icons/folder.svg" />
                            {{ $t('credit.menuModal.savedCalculations') }}
                        </a>
                        <a v-if="dorisMortgagesAllowed"
                           href="/Doris?login=true&redirectTo=/Mortgages/cz/MyApplications"
                           target="dorisLinkTarget">
                            <img src="@/assets/icons/dokumenty.svg" />
                            Online žádosti
                        </a>
                    </div>
                    <div class="menu-module-footer">
                        <div class="dropdown" style="padding-top:7px;">
                            <a href="javascript:void(0)"
                               data-toggle="dropdown"
                               aria-expanded="false">
                                <img src="@/assets/icons/help.svg" />
                                {{ $t('credit.menuModal.manual') }}
                            </a>
                            <ul class="dropdown-menu">
                                <template v-if="localization == 'cz'">
                                    <li>
                                        <a href="javascript:void(0)" @click="setUrlAndShowVideoModal('https://www.youtube.com/embed/hbSiCNRnSMY?si=-Zj4I-znVXW06YQh')">Propočet sazeb a metodický filtr</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)" @click="setUrlAndShowVideoModal('https://www.youtube.com/embed/i26VEwK34AU?si=C9GfZTw14lQHsHNQ')">Propočet bonity</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)" @click="setUrlAndShowVideoModal('https://www.youtube.com/embed/4tHHmoWHSok?si=VcJ9CLHzY6UUci_H_cs')">Metodika</a>
                                    </li>
                                </template>
                                <template v-if="localization == 'sk'">
                                    <li>
                                        <a href="javascript:void(0)" @click="setUrlAndShowVideoModal('https://www.youtube.com/embed/4m3q3iJNpo8?si=1IXn_zJZPDWwiy1e')">Prepočet sadzieb a metodický filter</a>
                                    </li>
                                    <li>
                                        <a href="javascript:void(0)" @click="setUrlAndShowVideoModal('https://www.youtube.com/embed/K7qY_HXknvo?si=q4cXY3ERBr-ePMTR')">Prepočet bonity</a>
                                    </li>
                                </template>
                            </ul>
                        </div>

                        <a href="/NewsList.aspx">
                            <img src="@/assets/icons/update-auto.svg" />
                            {{ $t('credit.menuModal.news') }}
                        </a>
                    </div>
                </div>

                <div v-if="suActive">
                    <div class="menu-link-group">
                        <a href="/Doris?login=true&redirectTo=/PersonalLoan/cz/List"
                           target="dorisLinkTarget">
                            <img src="@/assets/icons/sazby.svg" />
                            Nová kalkulace
                        </a>
                    </div>
                    <div class="menu-link-group">
                        <a href="/Doris?login=true&redirectTo=/PersonalLoan/cz/Payments"
                           target="dorisLinkTarget">
                            <img src="@/assets/icons/prijmy_pausal.svg" />
                            {{ $t('credit.menuModal.paymentCalendar') }}
                        </a>
                    </div>
                    <div class="menu-module-footer">
                        <a href="javascript:void(0)" @click="setUrlAndShowVideoModal('https://www.youtube.com/embed/HBe1T3vKpR0?si=Tsv3JCUlU_Fh9MPS')">
                            <img src="@/assets/icons/help.svg" />
                            {{ $t('credit.menuModal.manual') }}
                        </a>
                        <a href="/NewsList.aspx">
                            <img src="@/assets/icons/update-auto.svg" />
                            {{ $t('credit.menuModal.news') }}
                        </a>
                    </div>
                </div>
                <div v-if="nuActive">
                    <div class="menu-link-group">
                        <a href="/Doris?login=true&redirectTo=/Methodology/cz/Building/"
                           target="dorisLinkTarget">
                            <img src="@/assets/icons/crm.svg" />
                            Metodika
                        </a>
                        <a href="/Doris?login=true&redirectTo=/UnsecuredLoans/cz/modelation/create"
                           target="dorisLinkTarget">
                            <img src="@/assets/icons/sazby.svg" />
                            Nová kalkulace
                        </a>
                    </div>
                    <div class="menu-link-group">
                        <a href="/Doris?login=true&redirectTo=/UnsecuredLoans/cz/Payments"
                           target="dorisLinkTarget">
                            <img src="@/assets/icons/prijmy_pausal.svg" />
                            {{ $t('credit.menuModal.paymentCalendar') }}
                        </a>
                    </div>
                    <div class="menu-module-footer">
                        <a href="javascript:void(0)" @click="setUrlAndShowVideoModal('https://www.youtube.com/embed/NYHbpZKDjBk?si=WBspwAXXLwlOQxmM')">
                            <img src="@/assets/icons/help.svg" />
                            {{ $t('credit.menuModal.manual') }}
                        </a>
                        <a href="/NewsList.aspx">
                            <img src="@/assets/icons/update-auto.svg" />
                            {{ $t('credit.menuModal.news') }}
                        </a>
                    </div>
                </div>



                <div v-if="hypoActive"
                     class="menu-partners">
                    <span class="bold" style="color:gray">{{ $t('credit.menuModal.bankList') }}</span>
                    <div class="bank-pill-container">
                        <div class="bank-pill" v-for="partner in partnersSuperSorted" :key="partner.code">
                            <a target="_blank" :href="'/Poskytovatel/' + partner.code">
                                <img v-bTooltip="partner.name" :src="'/ImageProxy.aspx?f=' + partner.code + '-big.png'" />
                            </a>
                        </div>
                    </div>
                </div>
                <div v-if="suActive"
                     class="menu-partners">
                    <span class="bold" style="color:gray">{{ $t('credit.menuModal.bankList') }}</span>
                    <div class="bank-pill-container">
                        <div class="bank-pill">
                            <a target="_blank" href="/Doris?login=true&redirectTo=/PersonalLoan/cz/Partner/Detail/3">
                                <img v-bTooltip="'Československá obchodní banka'" src="/ImageProxy.aspx?f=CSOB-big.png" />
                            </a>
                        </div>
                        <div class="bank-pill">
                            <a target="_blank" href="/Doris?login=true&redirectTo=/PersonalLoan/cz/Partner/Detail/4">
                                <img v-bTooltip="'Komerční banka'" src="/ImageProxy.aspx?f=KB-big.png" />
                            </a>
                        </div>
                        <div class="bank-pill">
                            <a target="_blank" href="/Doris?login=true&redirectTo=/PersonalLoan/cz/Partner/Detail/5">
                                <img v-bTooltip="'UniCredit Bank'" src="/ImageProxy.aspx?f=UCB-big.png" />
                            </a>
                        </div>
                        <div class="bank-pill">
                            <a target="_blank" href="/Doris?login=true&redirectTo=/PersonalLoan/cz/Partner/Detail/6">
                                <img v-bTooltip="'Raiffeisenbank'" src="/ImageProxy.aspx?f=RB-big.png" />
                            </a>
                        </div>
                    </div>
                </div>
                <div v-if="nuActive"
                     class="menu-partners">
                    <span class="bold" style="color:gray">Produktové karty stavebních spořitelen</span>
                    <div class="bank-pill-container">
                        <div class="bank-pill">
                            <a target="_blank" href="/Doris?login=true&redirectTo=/UnsecuredLoans/cz/Partner/1">
                                <img v-bTooltip="'Raiffeisen stavební spořitelna'" src="/ImageProxy.aspx?f=RSTS-big.png" />
                            </a>
                        </div>
                        <div class="bank-pill">
                            <a target="_blank" href="/Doris?login=true&redirectTo=/UnsecuredLoans/cz/Partner/2">
                                <img v-bTooltip="'Modrá pyramida stavební spořitelna'" src="/ImageProxy.aspx?f=SSCS-big.png" />
                            </a>
                        </div>
                        <div class="bank-pill">
                            <a target="_blank" href="/Doris?login=true&redirectTo=/UnsecuredLoans/cz/Partner/4">
                                <img v-bTooltip="'Stavební spořitelna České spořitelny'" src="/ImageProxy.aspx?f=MPSS-big.png" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <v-video-dynamic-modal :videoSrc="videoSrc" />
    </div>
    
</template>

<script>

    import VideoDynamicModal from './components/VideoDynamicModal.vue'
    export default {
        name: 'app',
        components: {
            'v-video-dynamic-modal': VideoDynamicModal
        },
        data() {
            return {
                hypoActive: true,
                suActive: false,
                nuActive: false,
                videoSrc: '',

                partners: [],
                localization: '',
                dorisMortgagesAllowed: false,
                dorisPersonalLoansAllowed: false,
                dorisUnsecuredLoansAllowed: false,
                dorisLinkTarget: '',
                chartUrl: ''
            }
        },
        watch: {
        },
        methods: {
            redirectToDashboard() {
                window.location.href = '/Dashboard.aspx';
            },
            activateModule(name) {
                this.hypoActive = false;
                this.suActive = false;
                this.nuActive = false;
                if (name === 'Hypo') this.hypoActive = true;
                else if (name === 'SU') this.suActive = true;
                else if (name === 'NU') this.nuActive = true;
            },
            setUrlAndShowVideoModal(url) {
                this.videoSrc = url;
                $('#videoDynamicModal').modal();
            }
        },
        computed: {
            partnersSuperSorted() {
                function compareOB(a, b) {
                    //OB always last
                    if (a.code === 'OB') return 1;
                    if (b.code === 'OB') return -1;
                    return 0;
                }
                
                return this.partners.slice().sort(compareOB);
            }
        },
        props: {
            
        },
        mounted: function () {
            this.axios.post("/WebServices/MenuService.asmx/MenuData", { headers: { Accept: 'application/json' } })
                .then((response) => {
                    var res = JSON.parse(response.data.d);
                    this.partners = res.dummyPartners;
                    this.localization = res.localization;
                    this.dorisMortgagesAllowed = res.dorisMortgagesAllowed;
                    this.dorisPersonalLoansAllowed = res.dorisPersonalLoansAllowed;
                    this.dorisUnsecuredLoansAllowed = res.dorisUnsecuredLoansAllowed;
                    this.dorisLinkTarget = res.dorisLinkTarget;
                    this.chartUrl = res.chartUrl;
                });
        },
    }
</script>

